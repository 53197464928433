export const checkIfFileIsImage = (url: string): boolean => {
  // Define a list of valid image extensions
  const validImageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".webp",
    ".svg",
    ".ico",
  ];

  return validImageExtensions.some((ext) => url.endsWith(ext)) || false;
};
