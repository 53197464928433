import { useEffect, useContext, useState, useRef, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  TypingIndicator,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

import { useHttpClient } from "../../hooks/httpHook";
import { ModalError } from "./ModalError";
import { sendSupportMessage } from "../../api/chatAPI";
import { AuthContext } from "../../context/authContext";
import { getDateFromTimestamp } from "../../../shared/util/getDateFromTimestamp";
import { checkIfFileIsImage } from "../../../ticket/util/checkIfFileIsImage";
import { ChatData } from "../../data/types";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./WidgetZoe.scss";

interface ZoeChatProps {
  setChatVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  chatVisible?: boolean;
}

export const ZoeChat = (props: ZoeChatProps) => {
  const { chatVisible, setChatVisible } = props;
  const auth = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const { isLoading, error, success, sendRequest, clearError, clearSuccess } =
    useHttpClient();
  const [loadedChat, setLoadedChat] = useState<ChatData | null>(null);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  const [files, setFiles] = useState<File[]>([]); // State to store selected files
  const [editorHeight, setEditorHeight] = useState(0); // Track editor height
  const messageInputRef = useRef(null); // Create a ref for the MessageInput
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref to access the hidden file input
  const attachedFilesRef = useRef<HTMLDivElement>(null); // Ref to access the hidden file input

  useEffect(() => {
    if (auth.isLoggedIn && chatVisible) {
      sendSupportMessage({
        setLoadedChat,
        message: "",
        auth,
        sendRequest,
        files: [],
        history,
      }).finally(() => {
        messageInputRef.current?.focus();
      });
    }
  }, [auth.isLoggedIn, chatVisible]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      clearTimeout(timer);
    };
  }, []);

  // Scroll to bottom when files are updated
  useEffect(() => {
    // Query all elements with the class '.cs-message-list__scroll-wrapper'
    const scrollContainers = document.querySelectorAll<HTMLDivElement>(
      ".cs-message-list__scroll-wrapper"
    );

    scrollContainers.forEach((container) => {
      container.scrollTop = container.scrollHeight;
    });
  }, [files]);

  useEffect(() => {
    const editorElement = document.querySelector<HTMLDivElement>(
      ".cs-message-input__content-editor"
    );
    if (editorElement) {
      if (editorElement.scrollHeight < 82) {
        setEditorHeight(editorElement.scrollHeight); // Measure scrollHeight
      }
    }
  }, [messageInputRef?.current]);

  const measureEditorHeight = () => {
    const editorElement = document.querySelector<HTMLDivElement>(
      ".cs-message-input__content-editor"
    );
    if (editorElement) {
      if (editorElement.scrollHeight < 82) {
        setEditorHeight(editorElement.scrollHeight); // Measure scrollHeight
      }
    }
  };

  const sendMessageHandler = async (message: string) => {
    const attachedFiles =
      files?.map((f) => {
        return {
          _id: "",
          id: "",
          name: f?.name,
          url: "",
        };
      }) || [];

    setLoadedChat((prevValue) => {
      return {
        ...prevValue,
        conversationHistory: [
          ...prevValue.conversationHistory,
          {
            _id: "",
            id: "",
            role: "user",
            content: message,
            timestamp: new Date().getTime(),
            files: attachedFiles,
          },
        ],
      };
    });

    // Include files in the message payload if needed
    // You might need to adjust this part based on how your API handles file uploads
    sendSupportMessage({
      setLoadedChat,
      message: message,
      files: files || [], // Pass the files to your API
      sendRequest,
      auth,
      history,
    }).finally(() => {
      messageInputRef.current?.focus();
    });

    // Clear files after sending the message
    setFiles([]);
    setEditorHeight(0); // Reset editor height after sending
  };

  const onImagePaste = (imageFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const handlePaste = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      const items = e.clipboardData.items;
      const imageFiles: File[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          if (file) {
            imageFiles.push(file);
          }
        }
      }

      if (imageFiles.length > 0) {
        e.preventDefault(); // Prevent default paste behavior
        onImagePaste(imageFiles); // Pass images to parent
      }
    }
  };

  const changeChatVisibleHandler = () => {
    if (setChatVisible) {
      setChatVisible(!chatVisible);
    }
  };

  const resizeHandler = () => {
    setInnerWidth(window.innerWidth);
  };

  const attachFileHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the hidden file input
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      // Filter to include only image files
      const imageFiles = selectedFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      if (imageFiles.length !== selectedFiles.length) {
        alert("Apenas imagens são permitidas."); // Alert the user about invalid files
      }

      setFiles((prevFiles) => [...prevFiles, ...imageFiles]); // Append valid image files to the state

      // Clear the file input
      event.target.value = "";
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            {innerWidth <= 600 && setChatVisible && (
              <ConversationHeader.Back onClick={changeChatVisibleHandler} />
            )}
            <Avatar
              src={`${process.env.PUBLIC_URL}/zoe-azume.png`}
              name="Zoe"
            />
            <ConversationHeader.Content
              key={`conversation-zoe`}
              userName="Zoe"
              info="Online"
            />
          </ConversationHeader>
          <MessageList
            key={`conversation-zoe-message-list`}
            typingIndicator={
              isLoading ? (
                <TypingIndicator content="Zoe está digitando" />
              ) : null
            }
          >
            {loadedChat?.conversationHistory
              ?.filter((c) => c?.role !== "system")
              ?.map((c, index) => {
                const prevConv = loadedChat?.conversationHistory?.filter(
                  (c) => c?.role !== "system"
                )?.[index - 1];
                const isSameRole = prevConv?.role === c?.role;
                const thisMessageDate = getDateFromTimestamp(c?.timestamp);
                const prevMessageDate = getDateFromTimestamp(
                  prevConv?.timestamp
                );
                const isSameHour =
                  thisMessageDate?.substring(0, 15) ===
                  prevMessageDate?.substring(0, 15);

                const attachedFiles = c?.files || [];

                return (
                  <Fragment key={`support-message-frag-${index}`}>
                    {/* {(!isSameHour || index === 0) && (
                      <MessageSeparator
                        content={thisMessageDate?.replace("|", "-")}
                        key={`support-message-separator-${index}`}
                      />
                    )} */}
                    <Message
                      key={`support-message-${index}`}
                      model={{
                        message:
                          `${c?.content?.replace(
                            /Assistant: |User: /g,
                            ""
                          )}<p class="widget-zoe__chat-content-message-date widget-zoe__chat-content-message-date--${
                            c?.role === "user" ? "outgoing" : "incoming"
                          }" >${getDateFromTimestamp(c?.timestamp)?.replace(
                            "|",
                            "-"
                          )}</p>` || "",
                        sentTime: thisMessageDate,
                        sender: c?.role === "assistant" ? "Zoe" : "Eu",
                        direction:
                          c?.role === "assistant" ? "incoming" : "outgoing",
                        position: "first",
                      }}
                      // avatarSpacer={isSameRole}
                    >
                      {/* {c?.role === "assistant" &&
                      !isSameRole &&
                      innerWidth > 600 ? (
                        <Avatar
                          key={`support-message-avatar-${index}`}
                          src={`${process.env.PUBLIC_URL}/zoe-azume.png`}
                          name="Zoe"
                        />
                      ) : (
                        ""
                      )}
                      {c?.role === "user" && !isSameRole && innerWidth > 600 ? (
                        <Avatar
                          key={`support-message-avatar-${index}`}
                          src={`${process.env.PUBLIC_URL}/avatar-vendor.png`}
                        />
                      ) : (
                        ""
                      )} */}
                    </Message>
                    {attachedFiles.length > 0 &&
                      attachedFiles?.map((file, idx2) => {
                        const fileIsImage =
                          checkIfFileIsImage(file.url) || false;

                        return (
                          <Message
                            key={`support-message-attached-file-${index}-${idx2}`}
                            className={`${
                              c?.role === "user"
                                ? "cs-message__content--file-out"
                                : "cs-message__content--file-in"
                            } ${
                              !!file?.url
                                ? "cs-message__content--clickable"
                                : ""
                            }`}
                            onClick={() => {
                              if (!!file?.url) {
                                window.open(
                                  `${process.env.REACT_APP_ASSET_URL}/${file?.url}`,
                                  "_blank"
                                );
                              }
                            }}
                            model={{
                              message: !fileIsImage
                                ? file?.name || ""
                                : `
                          <div class="cs-message__content-img-container" >
                          <img class="cs-message__content-img" src="${
                            process.env.REACT_APP_ASSET_URL
                          }/${file?.url}" />
                          </div>${file?.name || ""}`,
                              sentTime: thisMessageDate,
                              sender: c?.role === "assistant" ? "Zoe" : "Eu",
                              direction:
                                c?.role === "assistant"
                                  ? "incoming"
                                  : "outgoing",
                              position: "first",
                            }}
                            // avatarSpacer={!fileIsImage}
                          >
                            {/* {fileIsImage && innerWidth > 600 && (
                              <Avatar
                                key={`support-message-attached-file-avatar-${index}-${idx2}`}
                                src={`${process.env.REACT_APP_ASSET_URL}/${file?.url}`}
                                name="File"
                              />
                            )} */}
                          </Message>
                        );
                      })}
                  </Fragment>
                );
              })}
            {files.length > 0 && (
              <div
                ref={attachedFilesRef}
                className="widget-zoe__attached-files"
                style={{ bottom: `${31 + editorHeight}px` }} // Adjust bottom dynamically
              >
                <ul>
                  {files?.map((file, index) => (
                    <li key={`file-li-${index}`}>
                      {/* Thumbnail */}
                      <img
                        key={`file-img-${index}`}
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="widget-zoe__thumbnail"
                      />
                      {/* Remove button */}
                      <button
                        key={`file-btn-${index}`}
                        className="widget-zoe__remove-button"
                        onClick={() => {
                          setFiles((prevFiles) =>
                            prevFiles.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        ×
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </MessageList>
          <MessageInput
            ref={messageInputRef} // Attach the ref to MessageInput
            disabled={isLoading}
            onPaste={(e) => {
              handlePaste(e as unknown as ClipboardEvent);
            }}
            placeholder="Digite sua mensagem aqui"
            onSend={(val) => {
              sendMessageHandler(val);
            }}
            onAttachClick={attachFileHandler}
            onChange={measureEditorHeight}
          />
        </ChatContainer>

        {/* Hidden file input to select files */}
        <input
          type="file"
          accept="image/*" // Restrict to image files only
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </MainContainer>
    </Fragment>
  );
};
