import React, {
  Suspense,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { MainNavigation } from "./shared/components/Navigation/MainNavigation";
import UserLogin from "./user/pages/UserLogin";
import HomeUser from "./general/pages/authenticated/HomeUser";
import WaitingPage from "./general/pages/authenticated/WaitingPage";
import { WidgetZendesk } from "./shared/components/UIElements/WidgetZendesk";
import { WidgetZoe } from "./shared/components/UIElements/WidgetZoe";
import { WidgetZoePasswordRecovery } from "./shared/components/UIElements/WidgetZoePasswordRecovery";
import { appTheme } from "./shared/theme/appTheme";
import { AuthContext } from "./shared/context/authContext";
import { SettingsContext } from "./shared/context/settingsContext";
import { useAuth } from "./shared/hooks/authHook";
import { useSettings } from "./shared/hooks/settingsHook";
import { LoadingSpinnerFullScreenGear } from "./shared/components/UIElements/LoadingSpinnerFullScreenGear";
import { useHttpClient } from "./shared/hooks/httpHook";
import { fetchIsAppUpdated } from "./general/api/homePageUserAPI";
import { getCustomDomainRedirects } from "./website/api/websiteAPI";
import { authZendeskUser } from "./general/api/generalAPI";
import { APP_VERSION } from "./shared/data/static";
import { CustomerImportMapping } from "./customer/pages/CustomerImportMapping";
import { SalesImportMapping } from "./sales/pages/SalesImportMapping";
import { KitImportMapping } from "./kits/pages/KitImportMapping";
import { PreSalesImportMapping } from "./sales/pages/PreSalesImportMapping";
import { UploadPreSales } from "./sales/pages/UploadPreSales";
import { InverterImportMapping } from "./equipaments/pages/InverterImportMapping";
import { EquipamentImportMapping } from "./equipaments/pages/EquipamentImportMapping";
import { ModuleImportMapping } from "./equipaments/pages/ModuleImportMapping";

import "react-photo-view/dist/react-photo-view.css";
import "./App.css";
import "./shared/components/Navigation/SideMenuStyles.scss";
import "./shared/styles/FormInputsGrids.scss";
import "./shared/styles/Utilities.scss";
import Chats from "./general/pages/authenticated/Chats";

const Admin1 = React.lazy(() => import("./admin/pages/Admin1"));
const Admin2 = React.lazy(() => import("./admin/pages/Admin2"));
const AdminFromAdm = React.lazy(() => import("./admin/pages/AdminFromAdm"));
const UserInfo = React.lazy(() => import("./user/pages/UserInfo"));
const UserPasswordExchange = React.lazy(
  () => import("./user/pages/UserPasswordExchange")
);
const UserPasswordReset = React.lazy(
  () => import("./user/pages/UserPasswordReset")
);
const CustomersList = React.lazy(
  () => import("./customer/pages/CustomersList")
);
const EditCustomer = React.lazy(() => import("./customer/pages/EditCustomer"));
const RegisterCustomer = React.lazy(
  () => import("./customer/pages/RegisterCustomer")
);
const CustomerExport = React.lazy(
  () => import("./customer/pages/CustomerExport")
);
const RegisterOrEditKit = React.lazy(
  () => import("./kits/pages/RegisterOrEditKit")
);
const KitsList = React.lazy(() => import("./kits/pages/KitsList"));
const GeneratorsDashboard = React.lazy(
  () => import("./kits/pages/GeneratorsDashboard")
);
const ProposalsListAll = React.lazy(
  () => import("./proposal/pages/ProposalsListAll")
);
const ProposalStepOne = React.lazy(
  () => import("./proposal/pages/ProposalStepOne")
);
const ProposalStepTwoKitChoice = React.lazy(
  () => import("./proposal/pages/ProposalStepTwoKitChoice")
);
const ProposalStepTwoKitData = React.lazy(
  () => import("./proposal/pages/ProposalStepTwoKitData")
);
const ProposalStepThreePricingChoice = React.lazy(
  () => import("./proposal/pages/ProposalStepThreePricingChoice")
);
const ProposalStepThreeFinData = React.lazy(
  () => import("./proposal/pages/ProposalStepThreeFinData")
);
const ProposalStepFourTheme = React.lazy(
  () => import("./proposal/pages/ProposalStepFourTheme")
);
const ProposalFinalDigital = React.lazy(
  () => import("./proposal/pages/ProposalFinalDigital")
);
const ProposalFinalPDF = React.lazy(
  () => import("./proposal/pages/ProposalFinalPDF")
);
const ProposalFinalPrint = React.lazy(
  () => import("./proposal/pages/ProposalFinalPrint")
);
const ProposalFinalFinancing = React.lazy(
  () => import("./proposal/pages/ProposalFinalFinancing")
);
const ProposalShareView = React.lazy(
  () => import("./proposal/pages/ProposalShareView")
);
const CustomizationMenu = React.lazy(
  () => import("./customization/pages/CustomizationMenu")
);
const Tutorials = React.lazy(
  () => import("./general/pages/authenticated/Tutorials")
);
const About = React.lazy(() => import("./general/pages/unauthenticated/About"));
const CaptureForm = React.lazy(
  () => import("./general/pages/unauthenticated/CaptureForm")
);
const CaptureFormCourses = React.lazy(
  () => import("./general/pages/unauthenticated/CaptureFormCourses")
);
const SignupStepOne = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepOne")
);
const SignupStepTwo = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepTwo")
);
const SignupStepThreeChoice = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepThreeChoice")
);
const SignupStepThreeCard = React.lazy(
  () => import("./general/pages/unauthenticated/SignupStepThreeCard")
);
const SinupFinishedCard = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedCard")
);
const SinupFinishedBill = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedBill")
);
const SinupFinishedTransfer = React.lazy(
  () => import("./general/pages/unauthenticated/SinupFinishedTransfer")
);
const Payments = React.lazy(
  () => import("./general/pages/authenticated/Payments")
);
const AccountSettings = React.lazy(
  () => import("./user/pages/AccountSettings")
);
const CancelPlan = React.lazy(
  () => import("./general/pages/authenticated/CancelPlan")
);
const Contact = React.lazy(() => import("./general/pages/Contact"));
const TermsOfServiceAndPrivacyPollicy = React.lazy(
  () =>
    import("./general/pages/unauthenticated/TermsOfServiceAndPrivacyPollicy")
);
const RegisterSale = React.lazy(() => import("./sales/pages/RegisterSale"));
const SalesFunnel = React.lazy(() => import("./sales/pages/SalesFunnel"));
const DashboardSales = React.lazy(() => import("./sales/pages/DashboardSales"));
const DashboardPreSales = React.lazy(
  () => import("./sales/pages/DashboardPreSales")
);
const PreSalesExport = React.lazy(() => import("./sales/pages/PreSalesExport"));
const DashboardProjects = React.lazy(
  () => import("./sales/pages/DashboardProjects")
);
const DashboardInstallations = React.lazy(
  () => import("./sales/pages/DashboardInstallations")
);
const SchedulesList = React.lazy(() => import("./sales/pages/SchedulesList"));
const SalesListFinshed = React.lazy(
  () => import("./sales/pages/SalesListFinished")
);
const SalesUpload = React.lazy(() => import("./sales/pages/SalesUpload"));
const SalesExport = React.lazy(() => import("./sales/pages/SalesExport"));
const UpdatesList = React.lazy(
  () => import("./general/pages/authenticated/UpdatesList")
);
const EditManager = React.lazy(() => import("./manager/pages/EditManager"));
const ProposalPDFContentFastRendering = React.lazy(
  () =>
    import(
      "./proposal/content/finalProposalPDF/ProposalPDFContentFastRendering"
    )
);
const Website = React.lazy(() => import("./website/pages/Website"));
const WebsiteCustomization = React.lazy(
  () => import("./website/pages/WebsiteCustomization")
);
const ContractsList = React.lazy(
  () => import("./contract/pages/ContractsList")
);
const EditContract = React.lazy(() => import("./contract/pages/EditContract"));
// const GenerateContract = React.lazy(
//   () => import("./contract/pages/GenerateContract")
// );
const SalesContractsList = React.lazy(
  () => import("./contract/pages/SalesContractsList")
);
const ViewSaleContract = React.lazy(
  () => import("./contract/pages/ViewSaleContract")
);
const UploadCustomers = React.lazy(
  () => import("./customer/pages/UploadCustomers")
);
const UploadKits = React.lazy(() => import("./kits/pages/UploadKits"));
const Simulator = React.lazy(() => import("./website/pages/Simulator"));
const CustomerFiles = React.lazy(() => import("./archive/pages/CustomerFiles"));
const ModulesList = React.lazy(() => import("./equipaments/pages/ModulesList"));
const RegisterOrEditModule = React.lazy(
  () => import("./equipaments/pages/RegisterOrEditModule")
);
const UploadModules = React.lazy(
  () => import("./equipaments/pages/UploadModules")
);
const InvertersList = React.lazy(
  () => import("./equipaments/pages/InvertersList")
);
const RegisterOrEditInverter = React.lazy(
  () => import("./equipaments/pages/RegisterOrEditInverter")
);
const UploadInverters = React.lazy(
  () => import("./equipaments/pages/UploadInverters")
);
const EquipamentsList = React.lazy(
  () => import("./equipaments/pages/EquipamentsList")
);
const RegisterOrEditEquipament = React.lazy(
  () => import("./equipaments/pages/RegisterOrEditEquipament")
);
const UploadEquipaments = React.lazy(
  () => import("./equipaments/pages/UploadEquipaments")
);
const ImportsList = React.lazy(() => import("./imports/pages/ImportsList"));
const SignupOrderPayment = React.lazy(
  () => import("./general/pages/signup/SignupOrderPayment")
);
const AfterSalesTemplatesList = React.lazy(
  () => import("./sales/pages/AfterSalesTemplatesList")
);
const WppAccountConnection = React.lazy(
  () => import("./user/pages/WppAccountConnection")
);
const ProposalStepThreeValidation = React.lazy(
  () => import("./proposal/pages/ProposalStepThreeValidation")
);
const ProposalStepThreeFinancing = React.lazy(
  () => import("./proposal/pages/ProposalStepThreeFinancing")
);
const FinancingsList = React.lazy(
  () => import("./financings/pages/FinancingsList")
);
const ProposalStepTwoAzumeKit = React.lazy(
  () => import("./proposal/pages/ProposalStepTwoAzumeKit")
);
const EstimatesList = React.lazy(
  () => import("./estimates/pages/EstimatesList")
);
const QualificationChat = React.lazy(
  () => import("./general/pages/unauthenticated/QualificationChat")
);
const UploadAfterSales = React.lazy(
  () => import("./sales/pages/UploadAfterSales")
);
const UploadActivites = React.lazy(
  () => import("./sales/pages/UploadActivites")
);
const AfterSalesImportMapping = React.lazy(
  () => import("./sales/pages/AfterSalesImportMapping")
);
const ActivitiesImportMapping = React.lazy(
  () => import("./sales/pages/ActivitiesImportMapping")
);
const CreateOrEditTicket = React.lazy(
  () => import("./ticket/pages/CreateOrEditTicket")
);
const TicketsList = React.lazy(() => import("./ticket/pages/TicketsList"));

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

function App() {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [addToHomescreenEvent, setAddToHomescreenEvent] =
    useState<BeforeInstallPromptEvent>(null);
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [needCriticalUpdate, setNeedCriticalUpdate] = useState<boolean>(false);
  const [customDomainUserId, setCustomDomainUserId] = useState<string>("");
  const [zendeskJWT, setZendeskJWT] = useState<string>(null);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [supportScreen, setSupportScreen] = useState<boolean>(false);
  const isStdDomain =
    window.location.hostname?.includes("azume") ||
    window.location.hostname?.includes("ase-intersolar") ||
    window.location.hostname?.includes("localhost");

  const {
    token,
    type,
    userId,
    email,
    personalData,
    managerId,
    managerUserId,
    vendors,
    userIsAdmin,
    statusOk,
    status,
    signupPlan,
    activeModules,
    whitelabel,
    login,
    logout,
  } = useAuth();

  window.addEventListener(
    "beforeinstallprompt",
    (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      const deferredPrompt = event;
      setAddToHomescreenEvent(deferredPrompt);
      return false;
    }
  );

  useEffect(() => {
    if (logout) {
      fetchIsAppUpdated({
        sendRequest,
        auth,
        logout,
        version: APP_VERSION,
        setNeedUpdate,
        setNeedCriticalUpdate,
      });
    }
  }, [logout]);

  useEffect(() => {
    if (
      window.location.hostname &&
      !window.location.hostname?.includes("localhost") &&
      !window.location.hostname?.includes("azume") &&
      !window.location.hostname?.includes("ase-intersolar")
    ) {
      getCustomDomainRedirects({
        sendRequest,
        setCustomDomainUserId,
        locationDomain: window.location.hostname,
      });
    }
  }, [window.location.hostname]);

  const {
    origins,
    salesLostReasons,
    rejectReasons,
    notesTypes,
    companyPositions,
    stdFolders,
    disabledSuppliers,
    afterSalesServices,
    addons,
    autoPricingType,
    autoPricingCalc,
    autoPricing,
    autoPricingDetailed,
    autoFinancing,
    preferences,
    permissions,
    individualPermissions,
    teams,
    suppliers,
    webhooks,
    userInterface,
    wppAccounts,
    proposalSlug,
    updateOriginsHandler,
    updateSalesLostReasonsHandler,
    updateRejectReasonsHandler,
    updateNotesTypesHandler,
    updateCompanyPositionsHandler,
    updateStdFoldersHandler,
    updateDisabledSuppliersHandler,
    updateAfterSalesServicesHandler,
    updateAutoPricingTypeHandler,
    updateAutoPricingCalcHandler,
    updateAutoPricingHandler,
    updateAutoPricingDetailedHandler,
    updateAutoFinancingHandler,
    updatePreferencesHandler,
    updatePermissionsHandler,
    updateIndividualPermissionsHandler,
    updateTeamsHandler,
    updateSuppliersHandler,
    updateWebhooksHandler,
    updateInterfaceHandler,
    updateWppAccountsHandler,
    updateProposalSlugHandler,
    updateAddonsHandler,
  } = useSettings();

  let routes: JSX.Element = null;

  if (token && !userIsAdmin && statusOk && isStdDomain) {
    routes = (
      <Switch>
        <Route path="/admin-from-adm/:email/:token/:ticketId?" exact>
          <div className="screen">
            <AdminFromAdm />
          </div>
        </Route>
        <Route path="/conta/info/:uid" exact>
          <div className="screen">
            <UserInfo />
          </div>
        </Route>
        <Route path="/conta/senha/:uid" exact>
          <div className="screen">
            <UserPasswordExchange />
          </div>
        </Route>
        <Route path="/clientes/lista/:uid" exact>
          <div className="screen">
            <CustomersList />
          </div>
        </Route>
        <Route path="/cliente/cadastro" exact>
          <div className="screen">
            <RegisterCustomer />
          </div>
        </Route>
        <Route path="/cliente/exportacao" exact>
          <div className="screen">
            <CustomerExport />
          </div>
        </Route>
        <Route path="/cliente/editar/:cid" exact>
          <div className="screen">
            <EditCustomer />
          </div>
        </Route>
        <Route path="/kits/lista/:uid" exact>
          <div className="screen">
            <KitsList screenContext="display" />
          </div>
        </Route>
        <Route
          path="/kits/escolha/:uid/:pid/:power/:powerGenUc/:powerNonPeak/:powerPeak/:demand/:targetPower/:ownerId/:distance/:location/:preVendor"
          exact
        >
          <div className="screen">
            <KitsList screenContext="use" />
          </div>
        </Route>
        <Route
          path="/kits/fornecedores/:uid/:pid/:power/:powerGenUc/:powerNonPeak/:powerPeak/:demand/:targetPower/:ownerId/:distance/:location/:preVendor"
          exact
        >
          <div className="screen">
            <GeneratorsDashboard />
          </div>
        </Route>
        <Route path="/kits/cadastro/:kitId" exact>
          <div className="screen">
            <RegisterOrEditKit />
          </div>
        </Route>
        <Route path="/kits/editar/:kitId" exact>
          <div className="screen">
            <RegisterOrEditKit />
          </div>
        </Route>
        <Route path="/propostas/lista/:uid" exact>
          <div className="screen">
            <ProposalsListAll />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-consumo/nova" exact>
          <div className="screen">
            <ProposalStepOne />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-consumo/editar" exact>
          <div className="screen">
            <ProposalStepOne />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/escolha-kit/:pid" exact>
          <div className="screen">
            <ProposalStepTwoKitChoice />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/dados-geracao/nova/:pid" exact>
          <div className="screen">
            <ProposalStepTwoKitData />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/kit-azume/:pid" exact>
          <div className="screen">
            <ProposalStepTwoAzumeKit />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/escolha/precificacao/:pid" exact>
          <div className="screen">
            <ProposalStepThreePricingChoice />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/preco-auto/:pid" exact>
          <div className="screen">
            <ProposalStepThreeFinData type="AUTO" />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/preco-editar/:pid" exact>
          <div className="screen">
            <ProposalStepThreeFinData type="EDIT" />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/validacao/:pid" exact>
          <div className="screen">
            <ProposalStepThreeValidation />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/financiamento/:pid" exact>
          <div className="screen">
            <ProposalStepThreeFinancing />
          </div>
        </Route>
        <Route path="/proposta/gerar-proposta/personalizacao/:pid" exact>
          <div className="screen">
            <ProposalStepFourTheme />
          </div>
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        {/* <Route path="/proposta/final/preview/pdf" exact>
          <ProposalFinalPDF preview />
        </Route> */}
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        {/* <Route path="/proposta/final/preview/impressao" exact>
          <ProposalFinalPrint preview />
        </Route> */}
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        {/* <Route path="/proposta/final/preview/financiamento" exact>
          <ProposalFinalFinancing preview />
        </Route> */}
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/arquivo/:pid" exact>
          <ProposalShareView />
        </Route>
        <Route path="/proposta/arquivo/:slug/:count/:code" exact>
          <ProposalShareView />
        </Route>
        <Route path="/personalizar-proposta/:uid" exact>
          <div className="screen">
            <CustomizationMenu />
          </div>
        </Route>
        <Route path="/vendas/registro" exact>
          <div className="screen">
            <RegisterSale type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/funil/:uid" exact>
          <div className="screen">
            <SalesFunnel type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/dados/:uid" exact>
          <div className="screen">
            <DashboardSales />
          </div>
        </Route>
        <Route path="/pre-venda/dados/:uid" exact>
          <div className="screen">
            <DashboardPreSales />
          </div>
        </Route>
        <Route path="/vendas/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="SALES" />
          </div>
        </Route>
        <Route path="/vendas/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="SALES" />
          </div>
        </Route>
        <Route path="/upload-de-clientes" exact>
          <div className="screen">
            <UploadCustomers />
          </div>
        </Route>
        <Route path="/upload-de-clientes/mapeamento" exact>
          <div className="screen">
            <CustomerImportMapping />
          </div>
        </Route>
        <Route path="/upload-de-kits" exact>
          <div className="screen">
            <UploadKits />
          </div>
        </Route>
        <Route path="/upload-de-kits/mapeamento" exact>
          <div className="screen">
            <KitImportMapping />
          </div>
        </Route>
        <Route path="/upload-de-vendas" exact>
          <div className="screen">
            <SalesUpload />
          </div>
        </Route>
        <Route path="/upload-de-vendas/mapeamento" exact>
          <div className="screen">
            <SalesImportMapping />
          </div>
        </Route>
        <Route path="/upload-de-pre-vendas" exact>
          <div className="screen">
            <UploadPreSales />
          </div>
        </Route>
        <Route path="/upload-de-pre-vendas/mapeamento" exact>
          <div className="screen">
            <PreSalesImportMapping />
          </div>
        </Route>
        <Route path="/upload-de-atividades-de-pos-venda" exact>
          <div className="screen">
            <UploadAfterSales />
          </div>
        </Route>
        <Route path="/upload-de-pos-venda/mapeamento" exact>
          <div className="screen">
            <AfterSalesImportMapping />
          </div>
        </Route>
        <Route path="/upload-de-projetos" exact>
          <div className="screen">
            <UploadActivites type="PROJECT" />
          </div>
        </Route>
        <Route path="/upload-de-projetos/mapeamento" exact>
          <div className="screen">
            <ActivitiesImportMapping type="PROJECT" />
          </div>
        </Route>
        <Route path="/upload-de-obras" exact>
          <div className="screen">
            <UploadActivites type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/upload-de-obras/mapeamento" exact>
          <div className="screen">
            <ActivitiesImportMapping type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/upload-de-operacoes-administrativas" exact>
          <div className="screen">
            <UploadActivites type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/upload-administrativo/mapeamento" exact>
          <div className="screen">
            <ActivitiesImportMapping type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/vendas/exportacao" exact>
          <div className="screen">
            <SalesExport />
          </div>
        </Route>
        <Route path="/projetos/registro" exact>
          <div className="screen">
            <RegisterSale type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/dados/:uid" exact>
          <div className="screen">
            <DashboardProjects />
          </div>
        </Route>
        <Route path="/projetos/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="PROJECT" />
          </div>
        </Route>
        <Route path="/projetos/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="PROJECT" />
          </div>
        </Route>
        <Route path="/instalacoes/registro" exact>
          <div className="screen">
            <RegisterSale type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/dados/:uid" exact>
          <div className="screen">
            <DashboardInstallations />
          </div>
        </Route>
        <Route path="/instalacoes/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/instalacoes/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="INSTALLATION" />
          </div>
        </Route>
        <Route path="/pos-venda/registro" exact>
          <div className="screen">
            <RegisterSale type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pos-venda/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="AFTER_SALES" />
          </div>
        </Route>
        <Route path="/pre-venda/registro" exact>
          <div className="screen">
            <RegisterSale type="PRE_SALES" />
          </div>
        </Route>
        <Route path="/pre-venda/funil/:uid" exact>
          <div className="screen">
            <SalesFunnel type="PRE_SALES" />
          </div>
        </Route>
        <Route path="/pre-venda/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="PRE_SALES" />
          </div>
        </Route>
        <Route path="/pre-venda/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="PRE_SALES" />
          </div>
        </Route>
        <Route path="/pre-venda/exportacao" exact>
          <div className="screen">
            <PreSalesExport />
          </div>
        </Route>
        <Route path="/administrativo/registro" exact>
          <div className="screen">
            <RegisterSale type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/administrativo/quadro/:uid" exact>
          <div className="screen">
            <SalesFunnel type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/administrativo/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/administrativo/finalizadas" exact>
          <div className="screen">
            <SalesListFinshed type="ADMINISTRATIVE" />
          </div>
        </Route>
        <Route path="/atualizacoes" exact>
          <div className="screen">
            <UpdatesList />
          </div>
        </Route>
        <Route path="/colaboradores/:managerId" exact>
          <div className="screen">
            <EditManager />
          </div>
        </Route>
        {/* <Route path="/tutoriais" exact>
          <div className="screen">
            <Tutorials />
          </div>
        </Route> */}
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/configuracoes" exact>
          <div className="screen">
            <AccountSettings />
          </div>
        </Route>
        <Route path="/cancelamento" exact>
          <div className="screen">
            <CancelPlan />
          </div>
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact
              inApp
              zendeskJWT={zendeskJWT}
              setZendeskJWT={setZendeskJWT}
              isAuth={isAuth}
            />
          </div>
        </Route>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Route path="/simulador/:id" exact>
          <Simulator />
        </Route>
        <Route path="/editar-website/:uid" exact>
          <div className="screen">
            <WebsiteCustomization />
          </div>
        </Route>
        <Route path="/contratos" exact>
          <div className="screen">
            <ContractsList />
          </div>
        </Route>
        <Route path="/escolher-contrato/:saleId" exact>
          <div className="screen">
            <ContractsList />
          </div>
        </Route>
        <Route path="/editar-contrato/:contractId" exact>
          <div className="screen">
            <EditContract />
          </div>
        </Route>
        {/* <Route path="/gerar-contrato/:contractId/:saleId" exact>
          <div className="screen">
            <GenerateContract />
          </div>
        </Route> */}
        <Route path="/visualizar-contratos-da-venda/:saleId" exact>
          <div className="screen">
            <SalesContractsList />
          </div>
        </Route>
        <Route path="/visualizar-contrato/:contractId/:saleId" exact>
          <div className="screen">
            <ViewSaleContract />
          </div>
        </Route>
        <Route path="/arquivos/:cid" exact>
          <div className="screen">
            <CustomerFiles />
          </div>
        </Route>
        <Route path="/modulos/lista" exact>
          <div className="screen">
            <ModulesList />
          </div>
        </Route>
        <Route path="/modulos/registro" exact>
          <div className="screen">
            <RegisterOrEditModule />
          </div>
        </Route>
        <Route path="/modulos/editar/:mid" exact>
          <div className="screen">
            <RegisterOrEditModule />
          </div>
        </Route>
        <Route path="/modulos/upload" exact>
          <div className="screen">
            <UploadModules />
          </div>
        </Route>
        <Route path="/modulos/upload/mapeamento" exact>
          <div className="screen">
            <ModuleImportMapping />
          </div>
        </Route>
        <Route path="/inversores/lista" exact>
          <div className="screen">
            <InvertersList />
          </div>
        </Route>
        <Route path="/inversores/registro" exact>
          <div className="screen">
            <RegisterOrEditInverter />
          </div>
        </Route>
        <Route path="/inversores/editar/:iid" exact>
          <div className="screen">
            <RegisterOrEditInverter />
          </div>
        </Route>
        <Route path="/inversores/upload" exact>
          <div className="screen">
            <UploadInverters />
          </div>
        </Route>
        <Route path="/inversores/upload/mapeamento" exact>
          <div className="screen">
            <InverterImportMapping />
          </div>
        </Route>
        <Route path="/equipamentos/lista" exact>
          <div className="screen">
            <EquipamentsList />
          </div>
        </Route>
        <Route path="/equipamentos/registro" exact>
          <div className="screen">
            <RegisterOrEditEquipament />
          </div>
        </Route>
        <Route path="/equipamentos/editar/:eid" exact>
          <div className="screen">
            <RegisterOrEditEquipament />
          </div>
        </Route>
        <Route path="/equipamentos/upload" exact>
          <div className="screen">
            <UploadEquipaments />
          </div>
        </Route>
        <Route path="/equipamentos/upload/mapeamento" exact>
          <div className="screen">
            <EquipamentImportMapping />
          </div>
        </Route>
        <Route path="/importacoes/lista" exact>
          <div className="screen">
            <ImportsList />
          </div>
        </Route>
        <Route path="/pos-venda/templates" exact>
          <div className="screen">
            <AfterSalesTemplatesList />
          </div>
        </Route>
        <Route path="/financiamentos" exact>
          <div className="screen">
            <FinancingsList />
          </div>
        </Route>
        <Route path="/pedidos" exact>
          <div className="screen">
            <EstimatesList />
          </div>
        </Route>
        <Route path="/tickets" exact>
          <div className="screen">
            <TicketsList />
          </div>
        </Route>
        <Route path="/ticket/:ticketId" exact>
          <div className="screen">
            <CreateOrEditTicket />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/form-teste-gratis-checkout" exact>
          <CaptureForm redirectToCheckout />
        </Route>
        <Route path="/form-cadastro-curso" exact>
          <CaptureFormCourses />
        </Route>
        <Route path="/pedido/:orderId" exact>
          <SignupOrderPayment />
        </Route>
        <Route path="/assinatura/parabens/:planId/:suId" exact>
          <SinupFinishedCard />
        </Route>
        <Route path="/assinatura/boleto/parabens/:suId" exact>
          <SinupFinishedBill />
        </Route>
        <Route path="/assinatura/pix/parabens/:suId" exact>
          <SinupFinishedTransfer />
        </Route>
        <Route path="/chat-solicitacao-teste" exact>
          <QualificationChat />
        </Route>
        <Route path="/termos-de-uso-e-politica-de-privacidade" exact>
          <div className="screen">
            <TermsOfServiceAndPrivacyPollicy />
          </div>
        </Route>
        <Route path="/inicio" exact>
          <div className="screen">
            <HomeUser
              needCriticalUpdate={needCriticalUpdate}
              needUpdate={needUpdate}
              setZendeskJWT={setZendeskJWT}
              zendeskJWT={zendeskJWT}
            />
          </div>
        </Route>
        <Route path="/iniciando" exact>
          <div className="screen">
            <WaitingPage />
          </div>
        </Route>
        <Redirect to="/iniciando" />
      </Switch>
    );
  } else if (token && !userIsAdmin && !statusOk && isStdDomain) {
    routes = (
      <Switch>
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/configuracoes" exact>
          <div className="screen">
            <AccountSettings />
          </div>
        </Route>
        <Route path="/cancelamento" exact>
          <div className="screen">
            <CancelPlan />
          </div>
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact
              inApp
              zendeskJWT={zendeskJWT}
              setZendeskJWT={setZendeskJWT}
              isAuth={isAuth}
            />
          </div>
        </Route>
        <Route path="/admin-from-adm/:email/:token/:ticketId?" exact>
          <div className="screen">
            <AdminFromAdm />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/form-teste-gratis-checkout" exact>
          <CaptureForm redirectToCheckout />
        </Route>
        <Route path="/form-cadastro-curso" exact>
          <CaptureFormCourses />
        </Route>
        <Route path="/clientes/lista/:uid" exact>
          <div className="screen">
            <CustomersList />
          </div>
        </Route>
        <Route path="/propostas/lista/:uid" exact>
          <div className="screen">
            <ProposalsListAll />
          </div>
        </Route>
        <Route path="/kits/lista/:uid" exact>
          <div className="screen">
            <KitsList screenContext="display" />
          </div>
        </Route>
        <Route path="/tickets" exact>
          <div className="screen">
            <TicketsList />
          </div>
        </Route>
        <Route path="/ticket/:ticketId" exact>
          <div className="screen">
            <CreateOrEditTicket />
          </div>
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/arquivo/:pid" exact>
          <ProposalShareView />
        </Route>
        <Route path="/proposta/arquivo/:slug/:count/:code" exact>
          <ProposalShareView />
        </Route>
        <Route path="/pedido/:orderId" exact>
          <SignupOrderPayment />
        </Route>
        <Route path="/assinatura/parabens/:planId/:suId" exact>
          <SinupFinishedCard />
        </Route>
        <Route path="/assinatura/boleto/parabens/:suId" exact>
          <SinupFinishedBill />
        </Route>
        <Route path="/assinatura/pix/parabens/:suId" exact>
          <SinupFinishedTransfer />
        </Route>
        <Route path="/chat-solicitacao-teste" exact>
          <QualificationChat />
        </Route>
        <Route path="/termos-de-uso-e-politica-de-privacidade" exact>
          <div className="screen">
            <TermsOfServiceAndPrivacyPollicy />
          </div>
        </Route>
        <Route path="/inicio" exact>
          <div className="screen">
            <HomeUser
              needCriticalUpdate={needCriticalUpdate}
              needUpdate={needUpdate}
              setZendeskJWT={setZendeskJWT}
              zendeskJWT={zendeskJWT}
            />
          </div>
        </Route>
        <Route path="/iniciando" exact>
          <div className="screen">
            <WaitingPage />
          </div>
        </Route>
        <Redirect to="/iniciando" />
      </Switch>
    );
  } else if (isStdDomain) {
    routes = (
      <Switch>
        <Route path="/vendas/agenda/:uid" exact>
          <div className="screen">
            <SchedulesList type="SALES" />
          </div>
        </Route>
        <Route path="/login" exact>
          <div className="screen">
            <UserLogin />
          </div>
        </Route>
        <Route path="/admin1" exact>
          <div className="screen">
            <Admin1 />
          </div>
        </Route>
        <Route path="/admin2" exact>
          <div className="screen">
            <Admin2 />
          </div>
        </Route>
        <Route path="/admin-from-adm/:email/:token/:ticketId?" exact>
          <div className="screen">
            <AdminFromAdm />
          </div>
        </Route>
        <Route path="/recuperacao-de-acesso" exact>
          <div className="screen">
            <UserPasswordReset />
          </div>
        </Route>
        <Route path="/sobre" exact>
          <div className="screen screen--no-side-menu">
            <About />
          </div>
        </Route>
        <Route path="/btg-cb" exact>
          <div className="screen screen--no-side-menu">
            <About />
          </div>
        </Route>
        <Route path="/form-teste-gratis" exact>
          <CaptureForm />
        </Route>
        <Route path="/form-teste-gratis-checkout" exact>
          <CaptureForm redirectToCheckout />
        </Route>
        <Route path="/form-cadastro-curso" exact>
          <CaptureFormCourses />
        </Route>
        <Route path="/contato" exact>
          <div className="screen">
            <Contact
              zendeskJWT={zendeskJWT}
              setZendeskJWT={setZendeskJWT}
              isAuth={isAuth}
            />
          </div>
        </Route>
        <Route path="/tickets" exact>
          <div className="screen">
            <TicketsList />
          </div>
        </Route>
        <Route path="/ticket/:ticketId" exact>
          <div className="screen">
            <CreateOrEditTicket />
          </div>
        </Route>
        <Route path="/chat-solicitacao-teste" exact>
          <QualificationChat />
        </Route>
        <Route path="/termos-de-uso-e-politica-de-privacidade" exact>
          <div className="screen">
            <TermsOfServiceAndPrivacyPollicy />
          </div>
        </Route>
        <Route path="/sobre/mobile" exact>
          <About />
        </Route>
        <Route path="/assinatura/passo-1/:planId" exact>
          <SignupStepOne />
        </Route>
        {/* <Route path="/assinatura/passo-2/:planId/:suId" exact>
          <SignupStepTwo />
        </Route> */}
        <Route path="/assinatura/escolha-pagamento/:planId/:suId" exact>
          <SignupStepThreeChoice />
        </Route>
        <Route path="/assinatura/passo-3/:planId/:suId" exact>
          <SignupStepThreeCard />
        </Route>
        <Route path="/assinatura/parabens/:planId/:suId" exact>
          <SinupFinishedCard />
        </Route>
        <Route path="/assinatura/boleto/parabens/:suId" exact>
          <SinupFinishedBill />
        </Route>
        <Route path="/assinatura/pix/parabens/:suId" exact>
          <SinupFinishedTransfer />
        </Route>
        <Route path="/proposta/final/pdf/:pid" exact>
          <ProposalFinalPDF />
        </Route>
        {/* <Route path="/proposta/final/preview/pdf" exact>
          <ProposalFinalPDF preview />
        </Route> */}
        <Route path="/proposta/final/impressao/:pid" exact>
          <ProposalFinalPrint />
        </Route>
        {/* <Route path="/proposta/final/preview/impressao" exact>
          <ProposalFinalPrint preview />
        </Route> */}
        <Route path="/proposta/final/financiamento/:pid" exact>
          <ProposalFinalFinancing />
        </Route>
        {/* <Route path="/proposta/final/preview/financiamento" exact>
          <ProposalFinalFinancing preview />
        </Route> */}
        <Route path="/proposta/final/mobile/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:pid" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/final/:slug/:count/:code" exact>
          <ProposalFinalDigital />
        </Route>
        <Route path="/proposta/arquivo/:pid" exact>
          <ProposalShareView />
        </Route>
        <Route path="/proposta/arquivo/:slug/:count/:code" exact>
          <ProposalShareView />
        </Route>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Route path="/simulador/:id" exact>
          <Simulator />
        </Route>
        <Route path="/financeiro" exact>
          <div className="screen">
            <Payments />
          </div>
        </Route>
        <Route path="/arquivos/:cid" exact>
          <div className="screen">
            <CustomerFiles />
          </div>
        </Route>
        <Route path="/pedido/:orderId" exact>
          <SignupOrderPayment />
        </Route>
        <Redirect
          to={!!localStorage?.getItem("loginPsd") ? "/login" : "/sobre"}
        />
      </Switch>
    );
  } else if (!isStdDomain) {
    routes = (
      <Switch>
        <Route path="/institucional/:id" exact>
          <Website />
        </Route>
        <Redirect to={`/institucional/${customDomainUserId}`} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token && !!userId && !!status,
        userId: userId,
        email: email,
        personalData: personalData,
        managerId: managerId,
        managerUserId: managerUserId,
        vendors: vendors,
        token: token,
        type: type,
        userIsAdmin: userIsAdmin,
        statusOk: statusOk,
        status: status,
        signupPlan: signupPlan,
        activeModules: activeModules,
        whitelabel: whitelabel,
        login: login,
        logout: logout,
      }}
    >
      <SettingsContext.Provider
        value={{
          origins,
          salesLostReasons,
          rejectReasons,
          notesTypes,
          companyPositions,
          stdFolders,
          disabledSuppliers,
          afterSalesServices,
          addons,
          autoPricingType,
          autoPricingCalc,
          autoPricing,
          autoPricingDetailed,
          autoFinancing,
          preferences,
          permissions,
          individualPermissions,
          teams,
          suppliers,
          webhooks,
          interface: userInterface,
          wppAccounts,
          proposalSlug,
          updateOriginsHandler,
          updateSalesLostReasonsHandler,
          updateRejectReasonsHandler,
          updateNotesTypesHandler,
          updateCompanyPositionsHandler,
          updateStdFoldersHandler,
          updateDisabledSuppliersHandler,
          updateAfterSalesServicesHandler,
          updateAutoPricingTypeHandler,
          updateAutoPricingCalcHandler,
          updateAutoPricingHandler,
          updateAutoPricingDetailedHandler,
          updateAutoFinancingHandler,
          updatePreferencesHandler,
          updatePermissionsHandler,
          updateIndividualPermissionsHandler,
          updateTeamsHandler,
          updateSuppliersHandler,
          updateInterfaceHandler,
          updateWebhooksHandler,
          updateWppAccountsHandler,
          updateProposalSlugHandler,
          updateAddonsHandler,
        }}
      >
        <MuiThemeProvider theme={appTheme}>
          <Router>
            {/* <NavContext.Provider
            value={{
              activeView: activeView,
              activeUrl: activeUrl,
              navigateHandler: navigateHandler,
            }}
          > */}
            <MainNavigation
              needUpdate={needUpdate}
              needCriticalUpdate={needCriticalUpdate}
              addToHomescreenEvent={addToHomescreenEvent}
              setAddToHomescreenEvent={setAddToHomescreenEvent}
            />
            {/* <WidgetZendesk
              zendeskJWT={zendeskJWT}
              setIsAuth={setIsAuth}
              isAuth={isAuth}
            /> */}
            <WidgetZoe />
            <WidgetZoePasswordRecovery />
            <main>
              {(window.location.hostname?.includes("azume") ||
                window.location.hostname?.includes("localhost") ||
                window.location.hostname?.includes("ase-intersolar")) &&
                !window.location.href?.includes("/institucional") &&
                !window.location.href?.includes("/proposta/final") && (
                  <Helmet>
                    <link
                      rel="icon"
                      href={`${process.env.PUBLIC_URL}/azume-icon-48x48.png`}
                    />
                    <title>Azume - CRM de Energia Solar</title>
                    <meta
                      name="description"
                      content="Azume CRM de Energia Solar - Turbine o faturamento da sua empresa de Energia Solar com o Azume!"
                    />
                    <meta property="og:url" content="https://azume.com.br" />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:image"
                      content={`${process.env.PUBLIC_URL}/azume-open-graph-logo.png`}
                    />
                    <meta
                      property="og:title"
                      content="Azume - CRM de Energia Solar<"
                    />
                    <meta
                      property="og:description"
                      content="Azume CRM de Energia Solar - Turbine o faturamento da sua empresa de Energia Solar com o Azume!"
                    />
                  </Helmet>
                )}
              {window.location.href?.includes("/proposta/final") && (
                <Helmet>
                  {/* <link
                  rel="icon"
                  href={`${process.env.PUBLIC_URL}/favicon.ico`}
                /> */}
                  <title>Proposta de Energia Solar Fotovoltaica</title>
                  <meta
                    name="description"
                    content="Chegou a sua proposta comercial para o sistema de Energia Solar Fotovoltaica. Clique e veja!"
                  />
                  <meta property="og:type" content="website" />
                  <meta
                    property="og:image"
                    content={`${process.env.PUBLIC_URL}/azume-og-image.jpg`}
                  />
                  <meta
                    property="og:title"
                    content="Sua Proposta de Energia Solar Fotovoltaica"
                  />
                  <meta
                    property="og:description"
                    content="Chegou a sua proposta comercial para o sistema de Energia Solar Fotovoltaica. Clique e veja!"
                  />
                </Helmet>
              )}
              <Suspense fallback={<LoadingSpinnerFullScreenGear fallback />}>
                <Fragment>
                  <Fragment>{routes}</Fragment>
                  {preferences?.proposalPDF?.useFastPDFRendering && (
                    <ProposalPDFContentFastRendering />
                  )}
                </Fragment>
              </Suspense>
            </main>
            {/* </NavContext.Provider> */}
          </Router>
        </MuiThemeProvider>
      </SettingsContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
