import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import {
  AuthContextProps,
  FormHookDispState,
  TicketData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

export interface FetchNoAuthProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
}

// GET SINGLE OPEN PASSWORD REC TICKET /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetSingleOpenPasswordRecTicketProps extends FetchNoAuthProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData>>;
  setCheckedOpenTicket: React.Dispatch<React.SetStateAction<boolean>>;
  ticketId: string;
}

export const getSingleOpenPasswordRecTicket = async (
  props: GetSingleOpenPasswordRecTicketProps
) => {
  const { sendRequest, setLoadedData, setCheckedOpenTicket, ticketId } = props;

  try {
    const responseData: {
      ticket: TicketData | null;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/password-recovery/${ticketId}`,
      "GET",
      null,
      {}
    );

    setLoadedData(responseData.ticket);
    setCheckedOpenTicket(true);
  } catch (err) {}
};

// GET SINGLE TICKET ///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetSingleTicketProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData>>;
  ticketId: string;
}

export const getSingleTicket = async (props: GetSingleTicketProps) => {
  const { sendRequest, setLoadedData, ticketId, auth } = props;

  try {
    const responseData: {
      ticket: TicketData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/user/${ticketId}`,
      "GET",
      null,
      {
        Authorization: `Bearer ${auth.token}`,
      }
    );

    setLoadedData(responseData.ticket);
  } catch (err) {}
};

// CREATE PASSWORD RECOVERY TICKET /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface CreatePasswordRecoverTicketProps extends FetchNoAuthProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData>>;
  formState: FormHookDispState;
}

export const createPasswordRecoverTicket = async (
  props: CreatePasswordRecoverTicketProps
) => {
  const { sendRequest, setLoadedData, formState } = props;

  try {
    const responseData: {
      ticket: TicketData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/new/password-recovery`,
      "POST",
      JSON.stringify({
        email: formState?.inputs?.email?.value,
        name: formState?.inputs?.name?.value,
        phone: formatPhoneNumber(formState?.inputs?.phone?.value?.toString()),
      }),
      {
        "Content-Type": "application/json",
      }
    );

    setLoadedData(responseData.ticket);
    localStorage.setItem(
      "openPasswordRecTicketId",
      responseData.ticket?._id || ""
    );
  } catch (err) {}
};

// GET PAGINATED TICKETS ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetPaginatedTicketsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getPaginatedTickets = async (props: GetPaginatedTicketsProps) => {
  const {
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const responseData: {
      items: TicketData[];
      hasMoreItems: boolean;
      totalItems: number;
      totalOpenItems: number;
      totalPendingItems: number;
      totalClosedItems: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/paginated/user/${multiplier}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      false
    );

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.items];
      } else {
        return responseData.items;
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// GET FILTERED TICKETS ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface GetFilteredTicketsProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData[]>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  timePeriod: {
    timePeriodEarlierSelected: string;
    timePeriodOlderSelected: string;
  };
  status?: "OPEN" | "PENDING" | "CLOSED";
  scope?: "USER_TICKETS" | "ACCOUNT_TICKETS";
  orderBy: "CREATION_DATE" | "LAST_MODIFIED_DATE";
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
}

export const getFilteredTickets = async (props: GetFilteredTicketsProps) => {
  const {
    auth,
    sendRequest,
    setLoadedData,
    setFiltered,
    searchValue,
    timePeriod,
    status,
    scope = "USER_TICKETS",
    orderBy,
    setShowLoadMore,
    multiplier,
    setMultiplier,
  } = props;

  try {
    const responseData: {
      items: TicketData[];
      hasMoreItems: boolean;
      totalItems: number;
      totalOpenItems: number;
      totalPendingItems: number;
      totalClosedItems: number;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/filtered/user/${multiplier}`,
      "POST",
      JSON.stringify({
        searchValue,
        timePeriod,
        status: !!status ? status : null,
        scope,
        orderBy,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      false,
      "items"
    );

    if (responseData && responseData.items?.length > 0) {
      setLoadedData((prevValues) => {
        if (!!prevValues && prevValues.length > 0 && multiplier > 0) {
          return [...prevValues, ...responseData.items];
        } else {
          return responseData.items;
        }
      });
    }
    setFiltered(true);
    setShowLoadMore(responseData.hasMoreItems);
    if (setMultiplier) {
      setMultiplier(0);
    }
  } catch (err) {}
};

// ADD USER MESSAGE TO PASSWORD REC TICKET /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface AddUserMessageToPasswordRecTicketProps extends FetchNoAuthProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData>>;
  ticketId: string;
  files: File[];
  message: string;
}

export const addUserMessageToPasswordRecTicket = async (
  props: AddUserMessageToPasswordRecTicketProps
) => {
  const { sendRequest, setLoadedData, ticketId, files = [], message } = props;

  const formData = new FormData();
  formData.append("message", message);
  files.forEach((file) => {
    formData.append("files", file as Blob);
  });

  try {
    const responseData: {
      ticket: TicketData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/conversation/password-recovery/${ticketId}`,
      "POST",
      formData,
      {},
      true
    );

    setLoadedData(responseData.ticket);
  } catch (err) {}
};

// ADD USER MESSAGE TO TICKET //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface AddUserMessageToTicketProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData>>;
  ticketId: string;
  isTicketOpening?: boolean;
  files: File[];
  formState: FormHookDispState;
  content: string;
  richContent: string;
}

export const addUserMessageToTicket = async (
  props: AddUserMessageToTicketProps
) => {
  const {
    sendRequest,
    setLoadedData,
    ticketId,
    auth,
    isTicketOpening = false,
    formState,
    files = [],
    content,
    richContent,
  } = props;

  const formData = new FormData();
  if (!!formState?.inputs?.title?.value) {
    formData.append("title", (formState?.inputs?.title?.value as string) || "");
  }
  formData.append("isTicketOpening", isTicketOpening?.toString() || "false");
  formData.append("content", content);
  formData.append("richContent", richContent);
  files.forEach((file) => {
    formData.append("files", file as Blob);
  });

  try {
    const responseData: {
      ticket: TicketData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/conversation/user/${ticketId}`,
      "POST",
      formData,
      {
        Authorization: `Bearer ${auth.token}`,
      },
      true
    );

    setLoadedData(responseData.ticket);
  } catch (err) {}
};

// EVALUATE TICKET /////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface EvaluateTicketProps extends FetchProps {
  setLoadedData1?: React.Dispatch<React.SetStateAction<TicketData[]>>;
  setLoadedData2?: React.Dispatch<React.SetStateAction<TicketData>>;
  formState: FormHookDispState;
  ticketId: string;
}

export const evaluateTicket = async (props: EvaluateTicketProps) => {
  const {
    sendRequest,
    setLoadedData1,
    setLoadedData2,
    ticketId,
    auth,
    formState,
  } = props;

  try {
    const responseData: {
      ticket: TicketData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/rating/${ticketId}`,
      "PUT",
      JSON.stringify({
        rating: +formState?.inputs?.rating?.value,
        review: formState?.inputs?.review?.value,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      true
    );

    if (setLoadedData1) {
      setLoadedData1((prevValues) => {
        return prevValues.map((ticket) => {
          if (ticket._id === ticketId) {
            return responseData.ticket;
          }
          return ticket;
        });
      });
    }

    if (setLoadedData2) {
      setLoadedData2(responseData.ticket);

      localStorage.setItem(
        "lastNonEvaluatedTicket",
        JSON.stringify({
          ticket: responseData.ticket,
          opened: true,
        })
      );
    }
  } catch (err) {}
};

// DELETE TICKET ///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
interface DeleteTicketProps extends FetchProps {
  setLoadedData: React.Dispatch<React.SetStateAction<TicketData[]>>;
  ticketId: string;
}

export const deleteTicket = async (props: DeleteTicketProps) => {
  const { sendRequest, setLoadedData, ticketId, auth } = props;

  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/tickets/single/${ticketId}`,
      "DELETE",
      null,
      {
        Authorization: `Bearer ${auth.token}`,
      },
      true
    );

    setLoadedData((prevValues) => {
      return prevValues.filter((ticket) => ticket._id !== ticketId);
    });
  } catch (err) {}
};
