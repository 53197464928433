import { AuthContextProps, ChatData, History } from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

export interface FetchNoAuthProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
}

// SEND SUPPORT MESSAGE /////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface SendSupportMessageProps extends FetchProps {
  setLoadedChat: React.Dispatch<React.SetStateAction<ChatData>>;
  message: string;
  files: File[];
  history: History;
}

export const sendSupportMessage = async (props: SendSupportMessageProps) => {
  const {
    sendRequest,
    setLoadedChat,
    message,
    auth,
    files = [],
    history,
  } = props;

  const formData = new FormData();
  formData.append("message", message);
  files.forEach((file) => {
    formData.append("files", file as Blob);
  });

  try {
    const responseData: {
      chat: ChatData;
      ticketId: string | null;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/chats/support`,
      "POST",
      formData,
      {
        Authorization: `Bearer ${auth.token}`,
      }
    );

    setLoadedChat(responseData?.chat);
    if (responseData?.ticketId) {
      history.push(`/ticket/${responseData?.ticketId}`);
    }
  } catch (err) {}
};
