import { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

import { useHttpClient } from "../../hooks/httpHook";
import { openNewTabUrl } from "../../util/openNewTabUrl";
import { getLastPublicNotification } from "../../api/notificationsAPI";
import { AuthContextProps, NotificationData } from "../../data/types";

import { ModalPromptStyles } from "./ModalPromptStyles";

interface ModalNotificationProps {
  auth: AuthContextProps;
  setIsModalNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalNotification = (props: ModalNotificationProps) => {
  const { auth, setIsModalNotificationOpen } = props;
  const classes = ModalPromptStyles();
  const firstAcess = !!JSON.parse(localStorage.getItem("first-access"))
    ? JSON.parse(localStorage.getItem("first-access")).firstAcess
    : true;
  const { sendRequest } = useHttpClient();
  const [lastPublicNotification, setLastPublicNotification] =
    useState<NotificationData>(null);
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (auth.isLoggedIn) {
      getLastPublicNotification({
        sendRequest,
        auth,
        setLastPublicNotification,
      });
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (lastPublicNotification) {
      const promptMemory = JSON.parse(
        localStorage.getItem(`prompt-notification-${lastPublicNotification.id}`)
      )?.shouldPrompt;

      const shouldPrompt =
        typeof promptMemory === "boolean" ? promptMemory : true;

      const promptTimestamp =
        JSON.parse(
          localStorage.getItem(
            `prompt-notification-${lastPublicNotification.id}`
          )
        )?.promptTimestamp || 0;
      const nowTimestamp = new Date().getTime();

      setOpen(shouldPrompt && nowTimestamp >= promptTimestamp);
    }
  }, [lastPublicNotification]);

  useEffect(() => {
    setIsModalNotificationOpen(open && !firstAcess);
  }, [open, firstAcess]);

  const dismissHandler = () => {
    setOpen(false);
    const promptTimestamp = new Date().getTime() + 24 * 60 * 60 * 1000;

    localStorage.setItem(
      `prompt-notification-${lastPublicNotification.id}`,
      JSON.stringify({ shouldPrompt: true, promptTimestamp: promptTimestamp })
    );
  };

  const confirmHandler = () => {
    if (
      lastPublicNotification?.title
        ?.toLowerCase()
        ?.includes("atualização disponível")
    ) {
      history.push("/atualizacoes");
      setOpen(false);
    } else {
      openNewTabUrl(lastPublicNotification.openUrl);
    }

    setOpen(false);

    localStorage.setItem(
      `prompt-notification-${lastPublicNotification.id}`,
      JSON.stringify({ shouldPrompt: false, promptTimestamp: 0 })
    );
  };

  return (
    <div>
      <Dialog open={open && !firstAcess} onClose={dismissHandler} scroll="body">
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={classes.confirmModalTitle}
        >
          {lastPublicNotification?.title}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
              }}
              src={`${process.env.PUBLIC_URL}/azume-iOS-adicionar-a-tela-inicial.webp`}
              alt={lastPublicNotification?.title}
              title={lastPublicNotification?.title}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 500,
              paddingTop: "1.2rem",
            }}
            className={classes.confirmModalContent}
          >
            <p>{lastPublicNotification?.content}</p>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
            gridGap: ".3rem",
          }}
        >
          <Button onClick={confirmHandler} color="primary">
            QUERO SABER MAIS!
          </Button>
          <Button
            style={{
              marginLeft: "0",
            }}
            onClick={dismissHandler}
            color="secondary"
          >
            LEMBRE-ME DEPOIS
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
