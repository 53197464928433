import { useEffect, useContext, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";

import { ZoeChatPasswordRecovery } from "./ZoeChatPasswordRecovery";
import { AuthContext } from "../../context/authContext";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./WidgetZoe.scss";

interface WidgetZoePasswordRecoveryProps {}

export const WidgetZoePasswordRecovery = (
  props: WidgetZoePasswordRecoveryProps
) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [chatVisible, setChatVisible] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<boolean>(true);

  useEffect(() => {
    const widget = document.getElementById("widget-zoe-password-id");

    if (!auth.isLoggedIn && location?.pathname?.includes("/login")) {
      widget?.classList?.remove("widget-zoe--no-display");
    } else {
      widget?.classList?.add("widget-zoe--no-display");
    }
  }, [auth.isLoggedIn, location.pathname]);

  const changeChatVisibleHandler = () => {
    setChatVisible(!chatVisible);
  };

  return (
    <Fragment>
      <div
        id="widget-zoe-password-id"
        className="widget-zoe widget-zoe--no-display"
      >
        <div
          className={`widget-zoe__chat-container ${
            chatVisible ? "widget-zoe__chat-container--visible" : ""
          } ${formMode ? "widget-zoe__chat-container--form" : ""}`}
        >
          <ZoeChatPasswordRecovery
            chatVisible={chatVisible}
            setChatVisible={setChatVisible}
            setFormMode={setFormMode}
          />
          {/* Visual component to display attached files */}
        </div>
        <div
          onClick={changeChatVisibleHandler}
          className={`widget-zoe__button ${
            chatVisible ? "widget-zoe__button--visible" : ""
          }`}
        >
          <div className="widget-zoe__button-open-icon">
            <ChatRoundedIcon />
          </div>
          <div className="widget-zoe__button-close-icon">
            <ExpandMoreRoundedIcon />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
