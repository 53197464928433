import React, { useContext, useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from "react-router-dom";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import GroupAddRoundedIcon from "@material-ui/icons/GroupAddRounded";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
import ViewListRoundedIcon from "@material-ui/icons/ViewListRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import DevicesRoundedIcon from "@material-ui/icons/DevicesRounded";
import ContactSupportRoundedIcon from "@material-ui/icons/ContactSupportRounded";
import CloudUploadRoundedIcon from "@material-ui/icons/CloudUploadRounded";
import ColorLensRoundedIcon from "@material-ui/icons/ColorLensRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import NetworkWifiRoundedIcon from "@material-ui/icons/NetworkWifiRounded";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import BuildRoundedIcon from "@material-ui/icons/BuildRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import SquareFootRoundedIcon from "@material-ui/icons/SquareFootRounded";
import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import WebRoundedIcon from "@material-ui/icons/WebRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import BackupRoundedIcon from "@material-ui/icons/BackupRounded";
import AssignmentTurnedInRoundedIcon from "@material-ui/icons/AssignmentTurnedInRounded";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import EventNoteRoundedIcon from "@material-ui/icons/EventNoteRounded";
import ForumRoundedIcon from "@material-ui/icons/ForumRounded";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import GradeRoundedIcon from "@material-ui/icons/GradeRounded";
import PaletteRoundedIcon from "@material-ui/icons/PaletteRounded";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import ConfirmationNumberRoundedIcon from "@material-ui/icons/ConfirmationNumberRounded";
import { exportCustomerssData } from "../../../customer/api/customersAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSolarPanel,
  faHandshake,
  faUserTie,
  faBoxes,
  faBox,
  faBoxOpen,
  faWaveSquare,
  faFilter,
  faPaste,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/httpHook";
import { AboutTrialFormPopover } from "../../../general/components/unauthenticated/AboutTrialFormPopover";
import { AuthContext } from "../../context/authContext";
import { NavContext } from "../../context/navContext";
import { SettingsContext } from "../../context/settingsContext";
import { addToHomescreenPrompt } from "../../util/addToHomescreenPrompt";
import { DesktopNavLinksProps } from "./DesktopNavLinks";
import { MenuEquipamentsOptions } from "./MenuEquipamentsOptions";
import { ACTIVE_VIEW_MAP } from "../../data/static";

import { MainNavigationStyles } from "./MainNavigationStyles";

import "./MobileNavLinksStyles.scss";

export interface MobileNavLinksProps extends DesktopNavLinksProps {
  clickCloser: () => void;
  sideMenu?: boolean;
  slug: string;
  openPurchaseModuleModalHandler: () => void;
}

export const MobileNavLinks = (props: MobileNavLinksProps) => {
  const classes = MainNavigationStyles();
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    clickCloser,
    sideMenu = false,
    slug,
    openPurchaseModuleModalHandler,
  } = props;
  const auth = useContext(AuthContext);
  const navCtx = useContext(NavContext);
  const settingsCtx = useContext(SettingsContext);
  const router = useHistory();

  const [navOneState, setNavOneState] = useState(false);
  const [navTwoState, setNavTwoState] = useState(false);
  const [navThreeState, setNavThreeState] = useState(false);
  const [navFourState, setNavFourState] = useState(false);
  const [navFiveState, setNavFiveState] = useState(false);
  const [navSixState, setNavSixState] = useState(false);
  const [navSevenState, setNavSevenState] = useState(false);
  const [navEightState, setNavEightState] = useState(false);
  const [navNineState, setNavNineState] = useState(false);
  const [navTenState, setNavTenState] = useState(false);
  const [navElevenState, setNavElevenState] = useState(false);
  const [navTwelveState, setNavTwelveState] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    null | HTMLButtonElement | HTMLSpanElement
  >(null);
  const [anchorElEqpMenu, setAnchorElEqpMenu] = useState<
    null | HTMLButtonElement | HTMLSpanElement | HTMLLIElement
  >(null);
  const [eqpMenuType, setEqpMenuType] = useState<"registro" | "upload">(
    "registro"
  );
  const { error, isLoading, sendRequest, clearError } = useHttpClient();
  const isSalesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;
  const isConfigManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.configManager || false;
  const isContractManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.contractManager || false;
  const isEmployeesManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.employeesManager || false;
  const isKitsManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.kitsManager || false;

  const hasVendorWppAccount = settingsCtx.wppAccounts?.some(
    (account) =>
      "vendor" in account &&
      account.vendor &&
      typeof account?.vendor === "object" &&
      account?.vendor?._id === auth?.userId
  );

  // useEffect(() => {
  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.customerList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.customerCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.customerEdit.view
  //   ) {
  //     setNavOneState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.kitsList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.kitsEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.kitsCreate.view
  //   ) {
  //     setNavFiveState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsAllList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsCustomerList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsCreateStep1New.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsCreateStep1Edit.view ||
  //     navCtx.activeView ===
  //       ACTIVE_VIEW_MAP.proposalsCreateStep2KitChoice.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsCreateStep2KitData.view ||
  //     navCtx.activeView ===
  //       ACTIVE_VIEW_MAP.proposalsCreateStep3PricingChoice.view ||
  //     navCtx.activeView ===
  //       ACTIVE_VIEW_MAP.proposalsCreateStep3PricingManual.view ||
  //     navCtx.activeView ===
  //       ACTIVE_VIEW_MAP.proposalsCreateStep3PricingAuto.view ||
  //     navCtx.activeView ===
  //       ACTIVE_VIEW_MAP.proposalsCreateStep3PricingEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.proposalsCreateStep4Theme.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.customizationMenu.view
  //   ) {
  //     setNavTwoState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.salesFunnel.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.salesCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.salesEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.salesData.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.salesArchivedList.view
  //   ) {
  //     setNavFourState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.tutorialsCourse.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.userInfoEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.userPasswordoEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.ordersMenu.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.ordersCancel.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.settings.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.contactForm.view
  //   ) {
  //     setNavThreeState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.websiteView.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.websiteEdit.view
  //   ) {
  //     setNavSixState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.projectsKanban.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.projectsCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.projectsEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.projectsSchedulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.projectsFinishedList.view
  //   ) {
  //     setNavSevenState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.installationsKanban.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.installationsCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.installationsEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.installationsSchedulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.installationsFinishedList.view
  //   ) {
  //     setNavEightState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.afterSalesKanban.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.afterSalesCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.afterSalesEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.afterSalesSchedulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.afterSalesFinishedList.view
  //   ) {
  //     setNavNineState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.modulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.moduleRegister.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.moduleEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.modulesUpload.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.invertersList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.inverterRegister.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.inverterEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.invertersUpload.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.equipamentsList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.equipamentRegister.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.equipamentEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.equipamentsUpload.view
  //   ) {
  //     setNavTenState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.preSalesKanban.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.preSalesCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.preSalesEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.preSalesSchedulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.preSalesFinishedList.view
  //   ) {
  //     setNavElevenState(true);
  //   }

  //   if (
  //     navCtx.activeView === ACTIVE_VIEW_MAP.administrativeKanban.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.administrativeCreate.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.administrativeEdit.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.administrativeSchedulesList.view ||
  //     navCtx.activeView === ACTIVE_VIEW_MAP.administrativeFinishedList.view
  //   ) {
  //     setNavTwelveState(true);
  //   }
  // }, [navCtx.activeView]);

  // useEffect(() => {
  //   // setNavOneState(false);
  //   // setNavTwoState(false);
  //   // setNavThreeState(false);
  //   // setNavFourState(false);
  //   // setNavFiveState(false);
  //   // setNavSixState(false);
  //   // setNavSevenState(false);
  //   // setNavEightState(false);
  //   // setNavNineState(false);
  //   // setNavTenState(false);
  // }, [auth.isLoggedIn]);

  const exportCustomersHandler = () => {
    exportCustomerssData({ sendRequest, auth });
  };

  const openEqpMenuHandler = (
    e: React.MouseEvent<HTMLElement>,
    type: "registro" | "upload"
  ) => {
    setAnchorElEqpMenu(e.currentTarget);
    setEqpMenuType(type);
  };

  return (
    <React.Fragment>
      <AboutTrialFormPopover
        id="popover-contact-video-section"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      {!sideMenu && (
        <div className="nav-close">
          <IconButton
            color="inherit"
            onClick={() => {
              clickCloser();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      )}
      {!sideMenu && (
        <div
          className={
            classes.navOptBtnMobile +
            `${navCtx.expand ? ` ${classes.navOptBtnMobile}--active` : ""}`
          }
          title={navCtx.expand ? "Esconder" : "Expandir"}
          onClick={() => {
            navCtx.expandHandler(!navCtx.expand);
          }}
        >
          <KeyboardArrowDownRoundedIcon />
        </div>
      )}
      <ul className="mobile-nav-links">
        <MenuEquipamentsOptions
          anchorEl={anchorElEqpMenu}
          setAnchorEl={setAnchorElEqpMenu}
          type={eqpMenuType}
          clickCloser={clickCloser}
        />
        {!auth.isLoggedIn && (
          <Fragment>
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to="/sobre">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.signupAbout.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <DevicesRoundedIcon />
                    SOBRE O AZUME
                  </Button>
                </NavLink>
              </li>{" "}
            </div>
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to="/login">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.loginUser.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <LockOpenRoundedIcon />
                    LOGIN DE USUÁRIO
                  </Button>
                </NavLink>
              </li>
            </div>
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn ${
                    navCtx.activeView === ACTIVE_VIEW_MAP.contactForm.view
                      ? "side-nav__btn--active"
                      : ""
                  }`}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <ContactSupportRoundedIcon />
                  ENTRAR EM CONTATO
                </Button>
              </li>
            </div>
          </Fragment>
        )}
        {auth.isLoggedIn && !!addToHomescreenEvent && (
          <div className="nav-division">
            <li className="nav-main-item">
              <NavLink to="/inicio">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn`}
                  onClick={() => {
                    addToHomescreenPrompt(
                      addToHomescreenEvent,
                      setAddToHomescreenEvent
                    );
                  }}
                >
                  <SystemUpdateAltRoundedIcon />
                  INSTALAR
                </Button>
              </NavLink>
            </li>
          </div>
        )}
        {auth.isLoggedIn && (
          <div className="nav-division">
            <li className="nav-main-item">
              <NavLink to="/inicio">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn ${
                    navCtx.activeView === ACTIVE_VIEW_MAP.home.view
                      ? "side-nav__btn--active"
                      : ""
                  }`}
                  onClick={() => {
                    clickCloser();
                  }}
                >
                  <HomeRoundedIcon />
                  INÍCIO
                </Button>
              </NavLink>
            </li>
          </div>
        )}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.customersList;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("CLIENTES")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavOneState(!navOneState);
                  }}
                >
                  <PersonRoundedIcon />
                  CLIENTES
                </Button>
              </li>
              {navOneState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/clientes/lista/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.customerList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <GroupRoundedIcon />
                      MEUS CLIENTES
                    </Button>
                  </NavLink>
                </li>
              )}
              {navOneState && (
                <li className="nav-secondary-item">
                  <NavLink to="/cliente/cadastro">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.customerCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <GroupAddRoundedIcon />
                      REGISTRAR CLIENTE
                    </Button>
                  </NavLink>
                </li>
              )}
              {navOneState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/upload-de-clientes">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.customersUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE CLIENTES
                    </Button>
                  </NavLink>
                </li>
              )}
              {navOneState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/cliente/exportacao">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.customerExport.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <CloudDownloadRoundedIcon />
                      EXPORTAR CLIENTES
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.proposalsList;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("PROPOSTAS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavTwoState(!navTwoState);
                  }}
                >
                  <ViewListRoundedIcon />
                  PROPOSTAS
                </Button>
              </li>
              {navTwoState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/propostas/lista/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.proposalsAllList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <ListAltRoundedIcon />
                      MINHAS PROPOSTAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTwoState && (
                <li className="nav-secondary-item">
                  <NavLink to="/proposta/gerar-proposta/dados-consumo/nova">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.proposalsCreateStep1New.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <PostAddRoundedIcon />
                      GERAR PROPOSTA
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTwoState && (auth.type !== "VENDOR" || isConfigManager) && (
                <li className="nav-secondary-item">
                  <NavLink to={`/personalizar-proposta/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.customizationMenu.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <ColorLensRoundedIcon />
                      PERSONALIZAÇÃO
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {auth.isLoggedIn &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return (
                ip.proposalsList || ip.salesKanban || ip.administrativeKanban
              );
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("FINANCIAMENTOS")) &&
          auth.whitelabel !== "SERRANA" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to="/financiamentos">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn side-nav__btn--teal ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.financings.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBillWave}
                      style={{
                        fontSize: "1rem",
                        marginLeft: ".22rem",
                        marginRight: ".2rem",
                      }}
                    />
                    FINANCIAMENTOS
                  </Button>
                </NavLink>
              </li>
            </div>
          )}
        {/* {auth.isLoggedIn &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return (
                ip.proposalsList || ip.salesKanban || ip.administrativeKanban
              );
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("PEDIDOS")) &&
          auth.whitelabel !== "SERRANA" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to="/pedidos">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${navCtx.activeView === ACTIVE_VIEW_MAP.orders?.view
                      ? "side-nav__btn--active"
                      : ""
                      }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <ShoppingCartRoundedIcon />
                    PEDIDOS
                  </Button>
                </NavLink>
              </li>
            </div>
          )} */}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{
                      fontSize: "1rem",
                      marginLeft: ".22rem",
                      marginRight: ".2rem",
                    }}
                  />
                  PRÉ-VENDA{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan === "ENTERPRISE" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.preSalesKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("PRÉ-VENDA")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavElevenState(!navElevenState);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{
                      fontSize: "1rem",
                      marginLeft: ".22rem",
                      marginRight: ".2rem",
                    }}
                  />
                  PRÉ-VENDA
                </Button>
              </li>
              {navElevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pre-venda/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.preSalesCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVO LEAD
                    </Button>
                  </NavLink>
                </li>
              )}
              {navElevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pre-venda/funil/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.preSalesKanban.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <NetworkWifiRoundedIcon />
                      FUNIL DE QUALIFICAÇÃO
                    </Button>
                  </NavLink>
                </li>
              )}
              {navElevenState && auth.signupPlan === "ENTERPRISE" && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pre-venda/dados/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.preSalesData?.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EqualizerRoundedIcon />
                      MÉTRICAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navElevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pre-venda/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.preSalesFinishedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      LEADS PROCESSADOS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navElevenState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/upload-de-pre-vendas">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.preSalesUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE LEADS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navElevenState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/pre-venda/exportacao">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.preSalesExport.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <CloudDownloadRoundedIcon />
                      EXPORTAÇÃO
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "BASIC" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.salesKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type !== "VENDOR") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("VENDAS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavFourState(!navFourState);
                  }}
                >
                  <AttachMoneyRoundedIcon />
                  VENDAS
                </Button>
              </li>
              {navFourState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/vendas/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.salesCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVA VENDA
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFourState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/vendas/funil/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.salesFunnel.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <NetworkWifiRoundedIcon />
                      FUNIL DE VENDAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFourState && auth.signupPlan === "ENTERPRISE" && (
                <li className="nav-secondary-item">
                  <NavLink to={`/vendas/dados/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.salesData.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EqualizerRoundedIcon />
                      MÉTRICAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFourState && auth.signupPlan !== "ENTERPRISE" && (
                <li
                  className={
                    "nav-secondary-item side-nav__btn side-nav__btn--premium"
                  }
                >
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.salesData.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={openPurchaseModuleModalHandler}
                  >
                    <EqualizerRoundedIcon />
                    MÉTRICAS{" "}
                    <GradeRoundedIcon className="side-nav__icon-premium" />
                  </Button>
                </li>
              )}
              {/* {navFourState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/vendas/agenda/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.salesSchedulesList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EventNoteRoundedIcon />
                      AGENDA DE VENDAS
                    </Button>
                  </NavLink>
                </li>
              )} */}
              {navFourState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/vendas/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.salesArchivedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      VENDAS FINALIZADAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFourState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/upload-de-vendas">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.salesUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE VENDAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFourState && (auth.type !== "VENDOR" || isSalesManager) && (
                <li className="nav-secondary-item">
                  <NavLink to="/vendas/exportacao">
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.salesExport.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <CloudDownloadRoundedIcon />
                      EXPORTAÇÃO
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <FontAwesomeIcon
                    icon={faPaste}
                    style={{
                      fontSize: "1rem",
                      marginLeft: ".55rem",
                      marginRight: ".1rem",
                    }}
                  />
                  ADMINISTRATIVO{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan === "ENTERPRISE" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.administrativeKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type === "MANAGER") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes(
              "ADMINISTRATIVO"
            )) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavTwelveState(!navTwelveState);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPaste}
                    style={{
                      fontSize: "1rem",
                      marginLeft: ".25rem",
                      marginRight: ".3rem",
                    }}
                  />
                  ADMINISTRATIVO
                </Button>
              </li>
              {navTwelveState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/administrativo/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.administrativeCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVA OPERAÇÃO
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTwelveState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/administrativo/quadro/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.administrativeKanban.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <DashboardRoundedIcon />
                      QUADRO DE OPERAÇÕES
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTwelveState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/administrativo/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.administrativeFinishedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      OPERAÇÕES FINALIZADAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTwelveState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/upload-de-operacoes-administrativas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.administrativeUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE OPERAÇÕES
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <SquareFootRoundedIcon />
                  PROJETOS{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan === "ENTERPRISE" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.projectKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type === "MANAGER") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("PROJETOS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavSevenState(!navSevenState);
                  }}
                >
                  <SquareFootRoundedIcon />
                  PROJETOS
                </Button>
              </li>
              {navSevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/projetos/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.projectsCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVO PROJETO
                    </Button>
                  </NavLink>
                </li>
              )}
              {navSevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/projetos/quadro/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.projectsKanban.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <DashboardRoundedIcon />
                      QUADRO DE PROJETOS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navSevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/projetos/dados/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.projectsData.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EqualizerRoundedIcon />
                      MÉTRICAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navSevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/projetos/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.projectsFinishedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      PROJETOS FINALIZADOS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navSevenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/upload-de-projetos`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.projectsUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE PROJETOS
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <BuildRoundedIcon />
                  OBRAS <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan === "ENTERPRISE" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.installationKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type === "MANAGER") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("OBRAS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavEightState(!navEightState);
                  }}
                >
                  <BuildRoundedIcon />
                  OBRAS
                </Button>
              </li>
              {navEightState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/instalacoes/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.installationsCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVA OBRA
                    </Button>
                  </NavLink>
                </li>
              )}
              {navEightState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/instalacoes/quadro/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.installationsKanban.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <DashboardRoundedIcon />
                      QUADRO DE OBRAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navEightState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/instalacoes/dados/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.installationsData.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EqualizerRoundedIcon />
                      MÉTRICAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navEightState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/instalacoes/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.installationsFinishedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      OBRAS FINALIZADAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navEightState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/upload-de-obras`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.installationsUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE OBRAS
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <SyncRoundedIcon />
                  PÓS-VENDA{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan === "ENTERPRISE" &&
          (settingsCtx.individualPermissions
            ?.filter((ip) => {
              return ip.afterSalesKanban;
            })
            ?.map((ip) => {
              return ip?.user;
            })
            .includes(auth.userId) ||
            auth.type === "MANAGER") &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("PÓS-VENDA")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavNineState(!navNineState);
                  }}
                >
                  <SyncRoundedIcon />
                  PÓS-VENDA
                </Button>
              </li>
              {navNineState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pos-venda/registro`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.afterSalesCreate.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AddRoundedIcon />
                      NOVA ATIVIDADE
                    </Button>
                  </NavLink>
                </li>
              )}
              {navNineState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pos-venda/quadro/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.afterSalesKanban.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <DashboardRoundedIcon />
                      QUADRO DE ATIVIDADES
                    </Button>
                  </NavLink>
                </li>
              )}
              {/* {navNineState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pos-venda/agenda/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.afterSalesSchedulesList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <EventNoteRoundedIcon />
                      AGENDA DE ATIVIDADES
                    </Button>
                  </NavLink>
                </li>
              )} */}
              {navNineState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/pos-venda/finalizadas`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.afterSalesFinishedList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <AssignmentTurnedInRoundedIcon />
                      ATIVIDADES FINALIZADAS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navNineState &&
                auth.activeModules?.includes(
                  "Gerador de Propostas de Pós-Venda"
                ) && (
                  <li className="nav-secondary-item">
                    <NavLink to={`/pos-venda/templates`}>
                      <Button
                        color="inherit"
                        style={{ width: "250px" }}
                        className={`side-nav__btn ${
                          navCtx.activeView ===
                          ACTIVE_VIEW_MAP.afterSalesTemplates.view
                            ? "side-nav__btn--active"
                            : ""
                        }`}
                        onClick={() => {
                          clickCloser();
                        }}
                      >
                        <PaletteRoundedIcon />
                        MODELOS DE PROPOSTAS
                      </Button>
                    </NavLink>
                  </li>
                )}
              {navNineState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/upload-de-atividades-de-pos-venda`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.afterSalesUpload.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <BackupRoundedIcon />
                      UPLOAD DE ATIVIDADES
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          auth.type === "MANAGER" &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("CHATS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to={`/chats`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${navCtx.activeView === ACTIVE_VIEW_MAP.chats.view
                      ? "side-nav__btn--active"
                      : ""
                      }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <ForumRoundedIcon />
                    CHATS
                  </Button>
                </NavLink>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("AGENDA")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to={`/vendas/agenda/${auth.userId}`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView ===
                      ACTIVE_VIEW_MAP.salesSchedulesList.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <EventNoteRoundedIcon />
                    AGENDA
                  </Button>
                </NavLink>
              </li>
            </div>
          )}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          !auth.whitelabel &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("KITS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavFiveState(!navFiveState);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSolarPanel}
                    style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                  />
                  KITS
                </Button>
              </li>
              {navFiveState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/kits/lista/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.kitsList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBoxes}
                        style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                      />
                      MEUS KITS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navFiveState &&
                (auth.type !== "VENDOR" ||
                  isKitsManager ||
                  !settingsCtx.preferences?.vendors?.useManagerKits) && (
                  <li className="nav-secondary-item">
                    <NavLink to="/kits/cadastro/0">
                      <Button
                        color="inherit"
                        style={{ width: "250px" }}
                        className={`side-nav__btn ${
                          navCtx.activeView === ACTIVE_VIEW_MAP.kitsCreate.view
                            ? "side-nav__btn--active"
                            : ""
                        }`}
                        onClick={() => {
                          clickCloser();
                        }}
                      >
                        <AddRoundedIcon />
                        REGISTRAR KIT
                      </Button>
                    </NavLink>
                  </li>
                )}
              {navFiveState &&
                (auth.type !== "VENDOR" ||
                  isKitsManager ||
                  !settingsCtx.preferences?.vendors?.useManagerKits) && (
                  <li className="nav-secondary-item">
                    <NavLink to="/upload-de-kits">
                      <Button
                        color="inherit"
                        style={{ width: "250px" }}
                        className={`side-nav__btn ${
                          navCtx.activeView === ACTIVE_VIEW_MAP.kitsUpload.view
                            ? "side-nav__btn--active"
                            : ""
                        }`}
                        onClick={() => {
                          clickCloser();
                        }}
                      >
                        <BackupRoundedIcon />
                        UPLOAD DE KITS
                      </Button>
                    </NavLink>
                  </li>
                )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <FontAwesomeIcon
                    icon={faBoxOpen}
                    style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                  />
                  EQUIPAMENTOS
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          !auth.whitelabel &&
          (isKitsManager || auth.type === "MANAGER") &&
          auth.signupPlan === "ENTERPRISE" &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("EQUIPAMENTOS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavTenState(!navTenState);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBoxOpen}
                    style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                  />
                  EQUIPAMENTOS
                </Button>
              </li>
              {navTenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/modulos/lista`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.modulesList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSolarPanel}
                        style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                      />
                      MÓDULOS
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/inversores/lista`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.invertersList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faWaveSquare}
                        style={{ fontSize: "1rem", marginLeft: ".15rem" }}
                      />
                      INVERSORES
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTenState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/equipamentos/lista`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView ===
                        ACTIVE_VIEW_MAP.equipamentsList.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBox}
                        style={{
                          fontSize: "1rem",
                          marginLeft: ".25rem",
                          marginRight: ".15rem",
                        }}
                      />
                      ITENS COMPLEMENTARES
                    </Button>
                  </NavLink>
                </li>
              )}
              {navTenState && (auth.type === "MANAGER" || isKitsManager) && (
                <li className="nav-secondary-item">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView ===
                        ACTIVE_VIEW_MAP.equipamentRegister.view ||
                      navCtx.activeView ===
                        ACTIVE_VIEW_MAP.moduleRegister.view ||
                      navCtx.activeView ===
                        ACTIVE_VIEW_MAP.inverterRegister.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={(e) => {
                      openEqpMenuHandler(e, "registro");
                    }}
                  >
                    <AddRoundedIcon />
                    REGISTRAR
                  </Button>
                </li>
              )}
              {navTenState && (auth.type === "MANAGER" || isKitsManager) && (
                <li className="nav-secondary-item">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView ===
                        ACTIVE_VIEW_MAP.equipamentsUpload.view ||
                      navCtx.activeView ===
                        ACTIVE_VIEW_MAP.modulesUpload.view ||
                      navCtx.activeView === ACTIVE_VIEW_MAP.invertersUpload.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={(e) => {
                      openEqpMenuHandler(e, "upload");
                    }}
                  >
                    <BackupRoundedIcon />
                    UPLOAD
                  </Button>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <FontAwesomeIcon
                    icon={faUserTie}
                    style={{
                      fontSize: "1rem",
                      marginLeft: ".3rem",
                      marginRight: ".33rem",
                    }}
                  />
                  COLABORADORES
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          (auth.type === "MANAGER" || isEmployeesManager) &&
          !!auth.managerId &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes(
              "COLABORADORES"
            )) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to={`/colaboradores/${auth.managerId}`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.managerVendors.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUserTie}
                      style={{
                        fontSize: "1rem",
                        marginLeft: ".3rem",
                        marginRight: ".33rem",
                      }}
                    />
                    COLABORADORES
                  </Button>
                </NavLink>
              </li>
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <LanguageRoundedIcon />
                  WEBSITE{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          (auth.type === "MANAGER" || isConfigManager) &&
          !!auth.managerId &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("WEBSITE")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    setNavSixState(!navSixState);
                  }}
                >
                  <LanguageRoundedIcon />
                  WEBSITE
                </Button>
              </li>
              {navSixState && (
                <li className="nav-secondary-item">
                  <NavLink
                    to={
                      !!slug
                        ? `/institucional/${slug}`
                        : `/institucional/${auth.userId}`
                    }
                    target="_blank"
                  >
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.websiteView.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <VisibilityRoundedIcon />
                      VISUALIZAR
                    </Button>
                  </NavLink>
                </li>
              )}
              {navSixState && (
                <li className="nav-secondary-item">
                  <NavLink to={`/editar-website/${auth.userId}`}>
                    <Button
                      color="inherit"
                      style={{ width: "250px" }}
                      className={`side-nav__btn ${
                        navCtx.activeView === ACTIVE_VIEW_MAP.websiteEdit.view
                          ? "side-nav__btn--active"
                          : ""
                      }`}
                      onClick={() => {
                        clickCloser();
                      }}
                    >
                      <WebRoundedIcon />
                      EDITAR
                    </Button>
                  </NavLink>
                </li>
              )}
            </div>
          )}
        {/* {auth.isLoggedIn &&
          !auth.userIsAdmin &&
          auth.signupPlan !== "ENTERPRISE" && (
            <div className="nav-division">
              <li className="nav-main-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn side-nav__btn--premium`}
                  onClick={openPurchaseModuleModalHandler}
                >
                  <DescriptionRoundedIcon />
                  CONTRATOS{" "}
                  <GradeRoundedIcon className="side-nav__icon-premium" />
                </Button>
              </li>
            </div>
          )} */}
        {auth.isLoggedIn &&
          (auth.type === "MANAGER" || isContractManager) &&
          !!auth.managerId &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("CONTRATOS")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to={`/contratos`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.contracts.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <DescriptionRoundedIcon />
                    CONTRATOS
                  </Button>
                </NavLink>
              </li>
            </div>
          )}
        {auth.isLoggedIn &&
          (auth.type !== "VENDOR" || isConfigManager) &&
          !!auth.managerId &&
          (navCtx?.expand ||
            !settingsCtx?.interface?.hiddenMenus?.includes("IMPORTAÇÕES")) && (
            <div className="nav-division">
              <li className="nav-main-item">
                <NavLink to={`/importacoes/lista`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.importsList.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <CloudUploadRoundedIcon />
                    IMPORTAÇÕES
                  </Button>
                </NavLink>
              </li>
            </div>
          )}
        {/* <div className="nav-division">
          <li className="nav-main-item">
            <a href="https://www.e4educacao.com.br/loja" target="_blank">
              <Button
                color="inherit"
                style={{ width: "250px" }}
                className={`side-nav__btn`}
              >
                <MenuBookRoundedIcon />
                E4 EDUCAÇÃO
              </Button>
            </a>
          </li>
        </div>
        {!auth.whitelabel && (
          <div className="nav-division">
            <li className="nav-main-item">
              <a href="https://www.e4educacao.com.br/parceiros" target="_blank">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className={`side-nav__btn`}
                >
                  <FontAwesomeIcon
                    icon={faHandshake}
                    style={{ fontSize: "1.1rem", marginLeft: ".14rem" }}
                  />
                  NOSSOS PARCEIROS
                </Button>
              </a>
            </li>
          </div>
        )} */}
        {auth.isLoggedIn && !auth.userIsAdmin && (
          <div className="nav-division">
            <li className="nav-main-item">
              <Button
                color="inherit"
                style={{ width: "250px" }}
                className="side-nav__btn"
                onClick={() => {
                  setNavThreeState(!navThreeState);
                }}
              >
                <AccountCircleRoundedIcon />
                MINHA CONTA
              </Button>
            </li>
            {navThreeState && (
              <li className="nav-secondary-item">
                <NavLink to={`/conta/info/${auth.userId}`}>
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.userInfoEdit.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <BusinessCenterRoundedIcon />
                    DADOS DA EMPRESA
                  </Button>
                </NavLink>
              </li>
            )}
            {/* {navThreeState && (
              <li className="nav-secondary-item">
                <NavLink to="/tutoriais">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.tutorialsCourse.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <VideoLibraryRoundedIcon />
                    CURSO AZUME
                  </Button>
                </NavLink>
              </li>
            )} */}
            {navThreeState && (
              <li className="nav-secondary-item">
                <NavLink to="/financeiro">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.ordersMenu.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <MonetizationOnRoundedIcon />
                    FINANCEIRO
                  </Button>
                </NavLink>
              </li>
            )}
            {navThreeState && (auth.type !== "VENDOR" || isConfigManager) && (
              <li className="nav-secondary-item">
                <NavLink to="/configuracoes">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.settings.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <SettingsIcon />
                    CONFIGURAÇÕES
                  </Button>
                </NavLink>
              </li>
            )}
            {navThreeState && hasVendorWppAccount && (
              <li className="nav-secondary-item">
                <NavLink to="/conta/whatsapp">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.ordersMenu.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <WhatsAppIcon />
                    WHATSAPP
                  </Button>
                </NavLink>
              </li>
            )}
            {navThreeState && (
              <li className="nav-secondary-item">
                <NavLink to="/contato">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.contactForm.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <ContactSupportRoundedIcon />
                    CHAMAR SUPORTE
                  </Button>
                </NavLink>
              </li>
            )}
            {navThreeState && (
              <li className="nav-secondary-item">
                <NavLink to="/tickets">
                  <Button
                    color="inherit"
                    style={{ width: "250px" }}
                    className={`side-nav__btn ${
                      navCtx.activeView === ACTIVE_VIEW_MAP.tickets.view
                        ? "side-nav__btn--active"
                        : ""
                    }`}
                    onClick={() => {
                      clickCloser();
                    }}
                  >
                    <ConfirmationNumberRoundedIcon />
                    TICKETS DE SUPORTE
                  </Button>
                </NavLink>
              </li>
            )}
            {navThreeState && (
              <li className="nav-secondary-item">
                <Button
                  color="inherit"
                  style={{ width: "250px" }}
                  className="side-nav__btn"
                  onClick={() => {
                    auth.logout();
                    router.push("/login");
                  }}
                >
                  <ExitToAppRoundedIcon />
                  SAIR
                </Button>
              </li>
            )}
          </div>
        )}
        {auth.whitelabel === "SERRANA" && (
          <div>
            <div className="mobile-nav-links__whitelabel-container">
              <img
                src={`${process.env.PUBLIC_URL}/azume-logo-app-light.png`}
                className="mobile-nav-links__whitelabel-image"
                alt="Gestão Solar powered by Azume"
              />
            </div>
          </div>
        )}
      </ul>
    </React.Fragment>
  );
};
