import { useEffect, useContext, useState, useRef, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  TypingIndicator,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

import { useHttpClient } from "../../hooks/httpHook";
import { useForm } from "../../../shared/hooks/formHook";
import { ModalError } from "./ModalError";
import { ModalInvalid } from "./ModalInvalid";
import {
  getSingleOpenPasswordRecTicket,
  createPasswordRecoverTicket,
  addUserMessageToPasswordRecTicket,
} from "../../../ticket/api/ticketsAPI";
import { Input } from "../../../shared/components/FormElements/Input";
import { InputPhone } from "../../../shared/components/FormElements/InputPhone";
import { LoadingSpinnerOverlayRegular } from "./LoadingSpinnerOverlayRegular";
import { AuthContext } from "../../context/authContext";
import { getDateFromTimestamp } from "../../util/getDateFromTimestamp";
import { checkIfFileIsImage } from "../../../ticket/util/checkIfFileIsImage";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../shared/util/validators";
import { AdminData, TicketData } from "../../data/types";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./WidgetZoe.scss";
import { is } from "date-fns/locale";
import zIndex from "@material-ui/core/styles/zIndex";

interface ZoeChatPasswordRecoveryProps {
  setChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  chatVisible: boolean;
  setFormMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ZoeChatPasswordRecovery = (
  props: ZoeChatPasswordRecoveryProps
) => {
  const { chatVisible, setChatVisible, setFormMode } = props;
  const auth = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const { isLoading, error, success, sendRequest, clearError, clearSuccess } =
    useHttpClient();
  const { formState, inputHandler, setFormData, reportInvalid } = useForm(
    {},
    false
  );
  const [loadedData, setLoadedData] = useState<TicketData | null>(null);
  const [checkedOpenTicket, setCheckedOpenTicket] = useState<boolean>(false);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  const [files, setFiles] = useState<File[]>([]); // State to store selected files
  const [editorHeight, setEditorHeight] = useState(0); // Track editor height
  const [invalidInputs, setInvalidInputs] = useState<{
    showInvalid: boolean;
    invalidFields: string[];
  }>({
    showInvalid: false,
    invalidFields: [],
  });
  const messageInputRef = useRef(null); // Create a ref for the MessageInput
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref to access the hidden file input
  const attachedFilesRef = useRef<HTMLDivElement>(null); // Ref to access the hidden file input

  useEffect(() => {
    if (!auth.isLoggedIn && chatVisible) {
      const openPasswordRecTicketId = localStorage.getItem(
        "openPasswordRecTicketId"
      ) as string;

      if (!!openPasswordRecTicketId) {
        getSingleOpenPasswordRecTicket({
          sendRequest,
          setLoadedData,
          setCheckedOpenTicket,
          ticketId: openPasswordRecTicketId,
        });
      } else {
        setCheckedOpenTicket(true);
      }
    }
  }, [auth.isLoggedIn, chatVisible]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    var timer = setTimeout(() => {
      resizeHandler();
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeHandler);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (loadedData) {
      setFormMode(false);
    } else {
      setFormMode(true);
    }

    if (chatVisible && loadedData && checkedOpenTicket) {
      var timer = setInterval(() => {
        if (!isLoading) {
          getSingleOpenPasswordRecTicket({
            sendRequest,
            setLoadedData,
            setCheckedOpenTicket,
            ticketId: loadedData?._id || "",
          });
        }
      }, 10 * 1000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loadedData]);

  useEffect(() => {
    const editorElement = document.querySelector<HTMLDivElement>(
      ".cs-message-input__content-editor"
    );
    if (editorElement) {
      if (editorElement.scrollHeight < 82) {
        setEditorHeight(editorElement.scrollHeight); // Measure scrollHeight
      }
    }
  }, [messageInputRef?.current]);

  const measureEditorHeight = () => {
    const editorElement = document.querySelector<HTMLDivElement>(
      ".cs-message-input__content-editor"
    );
    if (editorElement) {
      if (editorElement.scrollHeight < 82) {
        setEditorHeight(editorElement.scrollHeight); // Measure scrollHeight
      }
    }
  };

  const createPasswordRecoverTicketHandler = async () => {
    createPasswordRecoverTicket({
      sendRequest,
      setLoadedData,
      formState,
    });
  };

  const sendMessageHandler = async (message: string) => {
    const attachedFiles =
      files?.map((f) => {
        return {
          _id: "",
          name: f?.name,
          url: "",
        };
      }) || [];

    setLoadedData((prevValue) => {
      return {
        ...prevValue,
        conversationHistory: [
          ...prevValue.conversationHistory,
          {
            _id: "",
            role: "user",
            content: message,
            richContent: message,
            timestamp: new Date().getTime(),
            files: attachedFiles,
          } as TicketData["conversationHistory"][0],
        ],
      };
    });

    // Include files in the message payload if needed
    // You might need to adjust this part based on how your API handles file uploads
    addUserMessageToPasswordRecTicket({
      setLoadedData,
      message: message,
      files: files || [], // Pass the files to your API
      sendRequest,
      ticketId: loadedData?._id || "",
    }).finally(() => {
      messageInputRef.current?.focus();
    });

    // Clear files after sending the message
    setFiles([]);
    setEditorHeight(0); // Reset editor height after sending
  };

  const changeChatVisibleHandler = () => {
    if (setChatVisible) {
      setChatVisible(!chatVisible);
    }
  };

  const resizeHandler = () => {
    setInnerWidth(window.innerWidth);
  };

  const attachFileHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the hidden file input
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      // Filter to include only image files
      const imageFiles = selectedFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      if (imageFiles.length !== selectedFiles.length) {
        alert("Apenas imagens são permitidas."); // Alert the user about invalid files
      }

      setFiles((prevFiles) => [...prevFiles, ...imageFiles]); // Append valid image files to the state

      // Clear the file input
      event.target.value = "";
    }
  };

  const reportInvalidHandler = () => {
    const whatIsInvalid = reportInvalid(formState.inputs);
    setInvalidInputs({ showInvalid: true, invalidFields: whatIsInvalid });
  };

  const confirmModalCloseHandler = () => {
    setInvalidInputs({ showInvalid: false, invalidFields: [] });
  };

  const onImagePaste = (imageFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const handlePaste = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      const items = e.clipboardData.items;
      const imageFiles: File[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          if (file) {
            imageFiles.push(file);
          }
        }
      }

      if (imageFiles.length > 0) {
        e.preventDefault(); // Prevent default paste behavior
        onImagePaste(imageFiles); // Pass images to parent
      }
    }
  };

  const admin = loadedData?.admin as AdminData;

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalInvalid
        open={invalidInputs.showInvalid}
        closeHandler={confirmModalCloseHandler}
        message={invalidInputs.invalidFields.map((field) => {
          return <li key={field + "key"}>{field}</li>;
        })}
      />
      {checkedOpenTicket && !loadedData && (
        <form className="widget-zoe__form">
          {isLoading && (
            <LoadingSpinnerOverlayRegular image style={{ zIndex: 1002 }} />
          )}
          {/* <h2 className="widget-zoe__form-title">Recuperação de Acesso</h2> */}
          <p className="widget-zoe__form-subtitle">
            <b>Problemas de acesso?</b> Preencha o formulário para recuperar sua
            senha.
          </p>
          <Input
            id="name"
            type="text"
            label="primeiro Nome (nome pessoal)"
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText=""
            onInput={inputHandler}
            isName
            forceUpperCase
            reset={formState.reset}
            forceError={formState.inputs?.name?.forceError}
          />
          <Input
            id="email"
            type="email"
            label="e-mail da Conta"
            variant="outlined"
            validators={[VALIDATOR_EMAIL()]}
            helperText=""
            onInput={inputHandler}
            reset={formState.reset}
            forceError={formState.inputs?.email?.forceError}
          />
          <InputPhone
            id="phone"
            type="text"
            label="celular"
            variant="outlined"
            validators={[VALIDATOR_MINLENGTH(10)]}
            helperText=""
            onInput={inputHandler}
            reset={formState.reset}
            forceError={formState.inputs?.phone?.forceError}
          />
          {formState?.isValid && (
            <Button
              onClick={createPasswordRecoverTicketHandler}
              variant="contained"
              color="primary"
            >
              CHAMAR SUPORTE
            </Button>
          )}
          {!formState?.isValid && (
            <Button
              onClick={reportInvalidHandler}
              variant="outlined"
              color="secondary"
            >
              O QUE HÁ DE ERRADo?
            </Button>
          )}
          {/* <p className="widget-zoe__form-warning">
            <b>Importante:</b> Este canal é exclusivo para recuperação de senha.
            Para outras solicitações, faça login e contate o suporte.
          </p> */}
        </form>
      )}
      {checkedOpenTicket && loadedData && (
        <MainContainer>
          <ChatContainer>
            <ConversationHeader>
              {innerWidth <= 600 && setChatVisible && (
                <ConversationHeader.Back onClick={changeChatVisibleHandler} />
              )}
              <Avatar
                src={`${process.env.PUBLIC_URL}/avatar-azume-logo-only.png`}
                name={admin?.admin || "Agente Azume"}
              />
              <ConversationHeader.Content
                key={`conversation-password-recovery`}
                userName={admin?.admin || "Agente Azume"}
                info="Agente Azume"
              />
            </ConversationHeader>
            <MessageList key={`conversation-password-recovery-message-list`}>
              {loadedData?.conversationHistory?.map((c, index) => {
                const thisMessageDate = getDateFromTimestamp(c?.timestamp);
                const attachedFiles = c?.files || [];

                return (
                  <Fragment key={`support-message-frag-${index}`}>
                    {/* {(!isSameHour || index === 0) && (
                      <MessageSeparator
                        content={thisMessageDate?.replace("|", "-")}
                        key={`support-message-separator-${index}`}
                      />
                    )} */}
                    <Message
                      key={`support-message-${index}`}
                      model={{
                        message:
                          `${c?.content?.replace(
                            /Assistant: |User: /g,
                            ""
                          )}<p class="widget-zoe__chat-content-message-date widget-zoe__chat-content-message-date--${
                            c?.role === "user" ? "outgoing" : "incoming"
                          }" >${getDateFromTimestamp(c?.timestamp)?.replace(
                            "|",
                            "-"
                          )}</p>` || "",
                        sentTime: thisMessageDate,
                        sender: c?.role === "assistant" ? "Agente" : "Eu",
                        direction:
                          c?.role === "assistant" ? "incoming" : "outgoing",
                        position: "first",
                      }}
                      // avatarSpacer={isSameRole}
                    >
                      {/* {c?.role === "assistant" &&
                      !isSameRole &&
                      innerWidth > 600 ? (
                        <Avatar
                          key={`support-message-avatar-${index}`}
                          src={`${process.env.PUBLIC_URL}/zoe-azume.png`}
                          name="Zoe"
                        />
                      ) : (
                        ""
                      )}
                      {c?.role === "user" && !isSameRole && innerWidth > 600 ? (
                        <Avatar
                          key={`support-message-avatar-${index}`}
                          src={`${process.env.PUBLIC_URL}/avatar-vendor.png`}
                        />
                      ) : (
                        ""
                      )} */}
                    </Message>
                    {attachedFiles.length > 0 &&
                      attachedFiles?.map((file, idx2) => {
                        const fileIsImage =
                          checkIfFileIsImage(file.url) || false;

                        return (
                          <Message
                            key={`support-message-attached-file-${index}-${idx2}`}
                            className={`${
                              c?.role === "user"
                                ? "cs-message__content--file-out"
                                : "cs-message__content--file-in"
                            } ${
                              !!file?.url
                                ? "cs-message__content--clickable"
                                : ""
                            }`}
                            onClick={() => {
                              if (!!file?.url) {
                                window.open(
                                  `${process.env.REACT_APP_ASSET_URL}/${file?.url}`,
                                  "_blank"
                                );
                              }
                            }}
                            model={{
                              message: !fileIsImage
                                ? file?.name || ""
                                : `
                          <div class="cs-message__content-img-container" >
                          <img class="cs-message__content-img" src="${
                            process.env.REACT_APP_ASSET_URL
                          }/${file?.url}" />
                          </div>${file?.name || ""}`,
                              sentTime: thisMessageDate,
                              sender: c?.role === "assistant" ? "Agente" : "Eu",
                              direction:
                                c?.role === "assistant"
                                  ? "incoming"
                                  : "outgoing",
                              position: "first",
                            }}
                            // avatarSpacer={!fileIsImage}
                          >
                            {/* {fileIsImage && innerWidth > 600 && (
                              <Avatar
                                key={`support-message-attached-file-avatar-${index}-${idx2}`}
                                src={`${process.env.REACT_APP_ASSET_URL}/${file?.url}`}
                                name="File"
                              />
                            )} */}
                          </Message>
                        );
                      })}
                  </Fragment>
                );
              })}
              {files.length > 0 && (
                <div
                  ref={attachedFilesRef}
                  className="widget-zoe__attached-files"
                  style={{ bottom: `${31 + editorHeight}px` }} // Adjust bottom dynamically
                >
                  <ul>
                    {files?.map((file, index) => (
                      <li key={`file-li-${index}`}>
                        {/* Thumbnail */}
                        <img
                          key={`file-img-${index}`}
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="widget-zoe__thumbnail"
                        />
                        {/* Remove button */}
                        <button
                          key={`file-btn-${index}`}
                          className="widget-zoe__remove-button"
                          onClick={() => {
                            setFiles((prevFiles) =>
                              prevFiles.filter((_, i) => i !== index)
                            );
                          }}
                        >
                          ×
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </MessageList>
            <MessageInput
              ref={messageInputRef} // Attach the ref to MessageInput
              placeholder="Digite sua mensagem aqui"
              onSend={(val) => {
                sendMessageHandler(val);
              }}
              onPaste={(e) => {
                handlePaste(e as unknown as ClipboardEvent);
              }}
              onAttachClick={attachFileHandler}
              onChange={measureEditorHeight}
            />
          </ChatContainer>

          {/* Hidden file input to select files */}
          <input
            type="file"
            accept="image/*" // Restrict to image files only
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </MainContainer>
      )}
    </Fragment>
  );
};
