import { useEffect, useContext, useState, Fragment } from "react";
import { useLocation } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";

import { ZoeChat } from "./ZoeChat";
import { AuthContext } from "../../context/authContext";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import "./WidgetZoe.scss";

interface WidgetZoeProps {}

export const WidgetZoe = (props: WidgetZoeProps) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [chatVisible, setChatVisible] = useState<boolean>(false);

  useEffect(() => {
    const widget = document.getElementById("widget-zoe-id");

    if (
      auth.isLoggedIn &&
      auth.status === "ACTIVE" &&
      !location?.pathname?.includes("/proposta/final") &&
      !location?.pathname?.includes("/institucional") &&
      !location?.pathname?.includes("/kits/fornecedores") &&
      !location?.pathname?.includes("/configuracoes") &&
      !location?.pathname?.includes("/gerar-proposta/kit-azume") &&
      !location?.pathname?.includes("/personalizar-proposta") &&
      !location?.pathname?.includes("/agenda/") &&
      !location?.pathname?.includes("/contato") &&
      !location?.pathname?.includes("/ticket/")
    ) {
      widget?.classList?.remove("widget-zoe--no-display");
    } else {
      widget?.classList?.add("widget-zoe--no-display");
    }
  }, [auth.isLoggedIn, location.pathname]);

  const changeChatVisibleHandler = () => {
    setChatVisible(!chatVisible);
  };

  return (
    <Fragment>
      <div id="widget-zoe-id" className="widget-zoe widget-zoe--no-display">
        <div
          className={`widget-zoe__chat-container ${
            chatVisible ? "widget-zoe__chat-container--visible" : ""
          }`}
        >
          <ZoeChat chatVisible={chatVisible} setChatVisible={setChatVisible} />
          {/* Visual component to display attached files */}
        </div>
        <div
          onClick={changeChatVisibleHandler}
          className={`widget-zoe__button ${
            chatVisible ? "widget-zoe__button--visible" : ""
          }`}
        >
          <div className="widget-zoe__button-open-icon">
            <ChatRoundedIcon />
          </div>
          <div className="widget-zoe__button-close-icon">
            <ExpandMoreRoundedIcon />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
