import { useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useForm } from "../../shared/hooks/formHook";
import { InputRating } from "../../shared/components/FormElements/InputRating";
import { Input } from "../../shared/components/FormElements/Input";
import { ModalErrorStyles } from "../../shared/components/UIElements/ModalErrorStyles";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { VALIDATOR_VALID } from "../../shared/util/validators";
import { evaluateTicket } from "../api/ticketsAPI";
import { TicketData, AuthContextProps } from "../../shared/data/types";

import "./OrderItem.scss";

interface ModalEvaluateTicketProps {
  auth: AuthContextProps;
  ticket: TicketData;
  setLoadedData1?: React.Dispatch<React.SetStateAction<TicketData[]>>;
  setLoadedData2?: React.Dispatch<React.SetStateAction<TicketData>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  isLoading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNotification?: boolean;
}

export const ModalEvaluateTicket = (props: ModalEvaluateTicketProps) => {
  const {
    ticket,
    auth,
    setLoadedData1,
    setLoadedData2,
    isLoading,
    sendRequest,
    open,
    setOpen,
    isNotification,
  } = props;
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const classes = ModalErrorStyles();
  const classes2 = PopoverFormStyles();

  const closeHandler = () => {
    setOpen(false);
    localStorage.setItem(
      "lastNonEvaluatedTicket",
      JSON.stringify({
        ticket: ticket,
        opened: true,
      })
    );
  };

  const evaluateTicketHandler = async () => {
    if (!isNotification) {
      evaluateTicket({
        sendRequest,
        auth,
        setLoadedData1: setLoadedData1,
        ticketId: ticket._id,
        formState,
      }).then(() => {
        setOpen(false);
      });
    } else {
      evaluateTicket({
        sendRequest,
        auth,
        setLoadedData2: setLoadedData2,
        ticketId: ticket._id,
        formState,
      }).then(() => {
        setOpen(false);
      });
    }
  };

  return (
    <Dialog style={{ zIndex: 502 }} open={open} onClose={closeHandler}>
      <DialogActions style={{ paddingBottom: 0 }}>
        <Button style={{ height: "25px" }} size="small" onClick={closeHandler}>
          X
        </Button>
      </DialogActions>
      <DialogContent style={{ padding: 0, boxSizing: "border-box" }}>
        {isNotification && (
          <Fragment>
            <h2 className="ticket-item__form-title">
              Avalie o Nosso Atendimento
            </h2>
            <p className="ticket-item__form-subtitle">
              O ticket "{ticket?._id} - {ticket?.title}" foi finalizado. Por
              favor, avalie o atendimento da nossa equipe. Sua avaliação é muito
              importante para que possamos tornar nosso atendimento cada vez
              melhor!
            </p>
          </Fragment>
        )}
        <form style={{ zIndex: 502 }} className="ticket-item__form">
          <InputRating
            id="rating"
            inputHandler={inputHandler}
            initialValue={ticket?.customerRating / 2 || 3}
            updateInitialValue
            disabled={ticket?.status !== "CLOSED"}
          />
          <Input
            multiline
            rows={5}
            placeholder="Insira o comentário da sua avaliação aqui..."
            id="review"
            type="text"
            label="Comentário (opcional)"
            variant="outlined"
            validators={[VALIDATOR_VALID()]}
            helperText="Escreva a sua avaliação do atendimento (opcional)."
            onInput={inputHandler}
            isMessage
            initialValid
            reinitialize
            initialValue={ticket?.customerReview || ""}
          />
          {!isLoading && (
            <Button
              variant="contained"
              color="primary"
              disabled={!formState?.isValid || ticket?.status !== "CLOSED"}
              onClick={evaluateTicketHandler}
            >
              AVALIAR ATENDIMENTO
            </Button>
          )}
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};
