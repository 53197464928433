import { useState, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

import "./InputRating.scss";

const labels = {
  1: "Inútil",
  2: "Ruim",
  3: "Mediano",
  4: "Bom",
  5: "Excelente",
};

interface InputRatingProps {
  id: string;
  inputHandler: (
    id: string,
    value: string,
    isValid: boolean,
    label: string
  ) => void;
  initialValue?: number;
  updateInitialValue?: boolean;
  disabled?: boolean;
}

export const InputRating = (props: InputRatingProps) => {
  const { id, inputHandler, initialValue, updateInitialValue, disabled } =
    props;
  const [value, setValue] = useState<number | null>(initialValue || 5);
  const [hover, setHover] = useState(-1);

  useEffect(() => {
    inputHandler(id, value.toString(), true, "Avaliação");
  }, [id, value, inputHandler]);

  useEffect(() => {
    if (updateInitialValue) {
      setValue(initialValue);
    }
  }, [initialValue, updateInitialValue]);

  return (
    <div className="input-rating">
      <Rating
        value={value}
        name={id}
        disabled={disabled}
        precision={1}
        size="large"
        onChange={(event, newValue) => {
          if (!newValue && !disabled) {
            setValue(value);
          } else if (!disabled) {
            setValue(newValue);
          }
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {value !== null && (
        <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </div>
  );
};
